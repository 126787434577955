<template>
  <Toast />
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-info p-button-sm mr-2"
          @click="goBack()"
        />
        <div class="font-medium text-3xl text-900">Tambah Pengiriman</div>
        <Button
          @click="getRecyclers()"
          icon="pi pi-save"
          label="Simpan"
          class="p-button-success p-button-sm p-button-outlined ml-auto"
        />
        <Button
          icon="pi pi-check"
          label="Submit"
          class="p-button-success p-button-sm ml-2"
          @click="createDelivery()"
        />
      </div>
    </div>
    <form>
      <div class="grid p-fluid">
        <div class="col-8 mb-2">
          <div>
            <div id="map" />
          </div>
          <div class="field mt-2 mb-2">
            <label>Catatan</label>
            <div class="p-inputgroup">
              <Textarea
                rows="5"
                cols="30"
                :autoResize="true"
                style="border-radius: 8px"
                v-model="createDelivery$.notes.$model"
              />
            </div>
          </div>

          <div class="font-medium text-3xl text-900 mt-4">Penampung</div>
          <div v-for="recycler of recyclers" :key="recycler.id">
            <Card class="mt-3">
              <template #title>{{ recycler.companyName }}</template>
              <template #subtitle> {{ recycler.phoneNumber }} </template>
              <template #content>
                <p>
                  {{ recycler.address }}
                </p>
              </template>
              <template #footer>
                <div class="flex">
                  <Button
                    icon="pi pi-info"
                    label="Detail"
                    class="p-button-info p-button-small"
                  />
                  <Button
                    class="p-button-danger p-button-small"
                    icon="pi pi-times"
                    label="Remove"
                    style="margin-left: 0.5em"
                  />
                </div>
              </template>
            </Card>
          </div>
          <div v-if="recyclers.length < 1" class="mt-2">
            <h4
              v-if="recyclers.length < 1 && isLoadingGetRecycler == false"
              class="text-center"
            >
              Penampung tidak ditemukan!
            </h4>
            <div class="flex justify-center align-center">
              <ProgressSpinner
                v-if="isLoadingGetRecycler"
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
                aria-label="Custom ProgressSpinner"
              />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="field">
            <label>Dari Lokasi Awal</label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="createDelivery$.selectedProducer.$model"
                :options="producers"
                optionLabel="companyName"
                placeholder="Pilih producer"
                @click="onCompletePickupClick()"
              />
            </div>
          </div>
          <div class="field">
            <label>Dari Lokasi Akhir</label>
            <div class="p-inputgroup">
              <AutoComplete
                v-model="createDelivery$.destinationLocation.$model"
                optionLabel="value"
                :suggestions="suggestionDestinationLocations"
                @complete="onCompleteDestinationLocation"
                @item-select="onCompleteDestinationClick"
                :delay="delay"
              />
            </div>
          </div>
          <div class="field">
            <label>Jadwal</label>
            <div class="p-inputgroup">
              <Calendar v-model="createDelivery$.scheduleDate.$model" />
            </div>
          </div>
          <div class="field">
            <label>Estimasi Harga</label>
            <div class="p-inputgroup">
              <InputNumber v-model="createDelivery$.priceEstimation.$model" />
            </div>
          </div>
          <div class="field">
            <label>Radius Driver (dalam meter)</label>
            <div class="p-inputgroup">
              <InputNumber
                v-model="createDelivery$.driverRadius.$model"
                :useGrouping="false"
              />
            </div>
          </div>
          <div class="field">
            <label>Jenis Sampah</label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="createDelivery$.selectedWasteType.$model"
                :options="wasteTypes"
                optionLabel="wasteTypeName"
                optionValue="id"
                placeholder="Pilih jenis sampah"
                class="w-full md:w-14rem"
                @change="getWasteSubTypes()"
              />
              <Button
                icon="pi pi-plus"
                label="New"
                class="p-button-success p-button-sm p-button-outlined ml-auto"
                @click="showModalCreateWaste = true"
              />
            </div>
          </div>
          <div
            v-for="wasteSubType of wasteSubTypes"
            :key="wasteSubType.id"
            class="flex align-items-center"
          >
            <Checkbox
              v-model="createDelivery$.selectedWasteSubType.$model"
              :inputId="wasteSubType.id"
              name="wasteSubType"
              class="my-1 mr-2"
              :value="wasteSubType.id"
              @click="getRecyclers()"
            />
            <label :for="wasteSubType.id">{{
              wasteSubType.wasteSubTypeName
            }}</label>
          </div>
          <Button
            v-if="showButtonCreateWasteSubTypes"
            label="Tambah Jenis Produk"
            class="p-button-secondary p-button-sm p-button-outlined ml-auto mt-2"
            @click="showModalCreateWasteSubType = true"
          />
        </div>
      </div>
    </form>
  </div>

  <Dialog
    v-model:visible="showModalCreateWaste"
    modal
    header="Tambah Jenis Sampah"
    :style="{ width: '50vw' }"
  >
    <div class="grid p-fluid">
      <div class="col-12">
        <div class="field">
          <label for="title">Nama Jenis Sampah</label>
          <div class="p-inputgroup">
            <InputText v-model="addWasteType" />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        class="p-button-outlined"
        label="No"
        icon="pi pi-times"
        @click="showModalCreateWaste = false"
        text
      />
      <Button
        label="Tambah"
        icon="pi pi-check"
        @click="addWasteTypeSubmit()"
        autofocus
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="showModalCreateWasteSubType"
    modal
    header="Tambah Produk"
    :style="{ width: '50vw' }"
  >
    <div class="grid p-fluid">
      <div class="col-12">
        <div class="field">
          <label for="title">Nama Produk</label>
          <div class="p-inputgroup">
            <InputText v-model="addWasteSubType" />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        class="p-button-outlined"
        label="No"
        icon="pi pi-times"
        @click="showModalCreateWasteSubType = false"
        text
      />
      <Button
        label="Tambah"
        icon="pi pi-check"
        @click="addWasteSubTypeSubmit()"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import FormatCurrency from "../../services/helpers/FormatCurrency";
import { onMounted } from "@vue/runtime-core";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DeliveryService from "../../services/delivery/DeliveryService";
import polyline from "@mapbox/polyline";
import { useToast } from "primevue/usetoast";
import dayjs from "dayjs";

export default {
  setup() {
    const toast = useToast();
    let map = null;
    onMounted(async () => {
      getProducers();
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZmFjaHJ5cGl0dWt1IiwiYSI6ImNsaXM4YWltdDI5NXIzZWxnMzk1Z2Z1N24ifQ.XFdZGywcziEcmfRgJxD_jQ";
      map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v9",
        zoom: 8,
        center: [106.8594, -6.420465],
      });

      map.on("load", () => {
        // TODO: Here we want to load a layer
        // TODO: Here we want to load/setup the popup
        // drawPolyline()
      });

      getWasteTypes();
    });
    const router = useRouter();
    const delay = 2000;

    const createDeliveryState = reactive({
      selectedProducer: null,
      pickupLocationLat: 0.0,
      pickupLocationLng: 0.0,
      pickupLocationMarker: null,
      destinationLocation: "",
      destinationLocationLat: 0.0,
      destinationLocationLng: 0.0,
      destinationLocationMarker: null,
      selectedWasteType: "",
      selectedWasteSubType: [],
      scheduleDate: "",
      driverRadius: 0,
      priceEstimation: 0,
      recyclerMarkers: [],
      notes: "",
    });
    const createDeliveryRules = {
      selectedProducer: { required },
      pickupLocationLat: { required },
      pickupLocationLng: { required },
      destinationLocation: { required },
      destinationLocationLat: { required },
      destinationLocationLng: { required },
      selectedWasteType: { required },
      selectedWasteSubType: { required },
      scheduleDate: { required },
      driverRadius: { required },
      priceEstimation: { required },
      notes: {},
    };
    const createDelivery$ = useVuelidate(
      createDeliveryRules,
      createDeliveryState
    );

    const suggestionPickupLocations = ref([]);
    const suggestionDestinationLocations = ref([]);

    const addWasteType = ref("");
    const wasteTypes = ref([]);
    const addWasteSubType = ref("");
    const wasteSubTypes = ref([]);
    const producers = ref([]);
    const showButtonCreateWasteSubTypes = ref(false);

    const submitted = ref(false);
    const isLoading = ref(false);
    const isLoadingGetRecycler = ref(false);
    const recyclers = ref([]);
    const zoom = ref(2);

    const showModalCreateWaste = ref(false);
    const showModalCreateWasteSubType = ref(false);

    const createDelivery = async () => {
      isLoading.value = true;

      const listRecycler = recyclers.value.map((item) => ({
        recyclerId: item.id,
        load: 10,
      }));

      DeliveryService.addDelivery({
        source: {
          producerId: createDeliveryState.selectedProducer.id,
        },
        destination: {
          address: createDeliveryState.destinationLocation.value,
          latitude: createDeliveryState.destinationLocationLat,
          longitude: createDeliveryState.destinationLocationLng,
        },
        recyclers: listRecycler,
        wasteSubTypeIds: createDeliveryState.selectedWasteSubType,
        priceEstimation: createDeliveryState.priceEstimation || 0,
        deliveryDate: dayjs(createDeliveryState.scheduleDate).format(
          "YYYY-MM-DD"
        ),
        driverRadius: createDeliveryState.driverRadius,
        notes: createDeliveryState.notes,
      })
        .then(async (res) => {
          if ((res.status = 200 && res.data.status == "success")) {
            toast.add({
              severity: "success",
              summary: "Berhasil!",
              detail: res.data.message,
              life: 3000,
            });
            await router.push({ name: "listDelivery" });
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
            });
          } else if (err.request) {
            toast.add({
              severity: "error",
              summary: "ERR_CONNECTION_REFUSED",
              detail: err.request,
            });
          } else {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
            });
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const onCompletePickupClick = async () => {
      setTimeout(() => {
        console.log(createDeliveryState.selectedProducer);

        const el = document.createElement("div");
        el.className = "marker";
        el.style.backgroundImage = `url(https://res.cloudinary.com/pegilagi/image/upload/v1688618363/Icon_From_pcfzge.png)`;
        el.style.width = "30px";
        el.style.height = "30px";
        el.style.backgroundSize = "100%";

        if (!createDelivery.pickupLocationMarker) {
          createDelivery.pickupLocationMarker = new mapboxgl.Marker(el)
            .setLngLat([
              createDeliveryState.selectedProducer.longitude,
              createDeliveryState.selectedProducer.latitude,
            ])
            .addTo(map);
        } else {
          createDelivery.pickupLocationMarker.remove();
          createDelivery.pickupLocationMarker = new mapboxgl.Marker(el)
            .setLngLat([
              createDeliveryState.selectedProducer.longitude,
              createDeliveryState.selectedProducer.latitude,
            ])
            .addTo(map);
        }

        // re-render polyline
        if (
          createDelivery.pickupLocationMarker &&
          createDelivery.destinationLocationMarker &&
          recyclers.value.length > 1
        ) {
          drawPolyline();
        }
      }, 1500);
    };

    const onCompleteDestinationClick = async (event) => {
      const res = await DeliveryService.getPlace(event.value.id);
      createDeliveryState.destinationLocationLat =
        res.data.result.geometry.location.lat;
      createDeliveryState.destinationLocationLng =
        res.data.result.geometry.location.lng;

      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(https://res.cloudinary.com/pegilagi/image/upload/v1688618363/Destination_Icon_pqxnsi.png)`;
      el.style.width = "30px";
      el.style.height = "30px";
      el.style.backgroundSize = "100%";

      if (!createDelivery.destinationLocationMarker) {
        createDelivery.destinationLocationMarker = new mapboxgl.Marker(el)
          .setLngLat([
            res.data.result.geometry.location.lng,
            res.data.result.geometry.location.lat,
          ])
          .addTo(map);
      } else {
        createDelivery.destinationLocationMarker.remove();
        createDelivery.destinationLocationMarker = new mapboxgl.Marker(el)
          .setLngLat([
            res.data.result.geometry.location.lng,
            res.data.result.geometry.location.lat,
          ])
          .addTo(map);
      }

      // re-render polyline
      if (
        createDelivery.pickupLocationMarker &&
        createDelivery.destinationLocationMarker &&
        recyclers.value.length > 1
      ) {
        drawPolyline();
      }
    };
    const onCompleteDestinationLocation = async (event) => {
      const res = await DeliveryService.queryAutoCompletePlaces(event.query);
      suggestionDestinationLocations.value = res.data.predictions.map(
        (location) => ({
          value: location.description,
          id: location.place_id,
        })
      );
    };

    const drawPolyline = async () => {
      const getPickupPoint = recyclers.value.map((pickupPoint) => [
        pickupPoint.longitude,
        pickupPoint.latitude,
      ]);

      const res = await DeliveryService.getPolyline([
        [
          createDeliveryState.selectedProducer.longitude,
          createDeliveryState.selectedProducer.latitude,
        ],
        ...getPickupPoint,
        [
          createDeliveryState.destinationLocationLng,
          createDeliveryState.destinationLocationLat,
        ],
      ]);

      const encodedPolyline = res.data.routes[0].geometry;
      const decodedPolyline = polyline.decode(encodedPolyline);
      const coordinates = decodedPolyline.map((coordinate) => {
        return [coordinate[1], coordinate[0]];
      });

      const polylineGeoJSON = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinates,
        },
      };

      map.addSource("polyline", {
        type: "geojson",
        data: polylineGeoJSON,
      });

      map.addLayer({
        id: "polyline",
        type: "line",
        source: "polyline",
        paint: {
          "line-color": "#8ecbc4", // Set the color of the polyline
          "line-width": 2, // Set the width of the polyline
        },
      });
    };

    const getProducers = async () => {
      isLoading.value = true;
      DeliveryService.getProducers()
        .then((res) => {
          producers.value = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getRecyclers = async () => {
      isLoadingGetRecycler.value = true;
      setTimeout(() => {
        DeliveryService.getNearestRecyclers({
          source: {
            latitude: createDeliveryState.pickupLocationLat,
            longitude: createDeliveryState.pickupLocationLng,
          },
          destination: {
            latitude: createDeliveryState.destinationLocationLat,
            longitude: createDeliveryState.destinationLocationLng,
          },
          wasteSubTypeIds: createDeliveryState.selectedWasteSubType,
        })
          .then((res) => {
            recyclers.value = res.data.data;
            createDeliveryState.recyclerMarkers = res.data.data.map((item, index) => {
              const el = document.createElement("div");
              el.className = "marker";
              el.style.backgroundImage = `url(https://ui-avatars.com/api/?background=90AEC9&color=fff&font-size=0.6&bold=true&size=128&name=${index+1})`;
              el.style.width = "30px";
              el.style.height = "30px";
              el.style.backgroundSize = "100%";
              
              new mapboxgl.Marker(el)
                .setLngLat([item.longitude, item.latitude])
                .addTo(map);
            });
            drawPolyline();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            isLoadingGetRecycler.value = false;
          });
      }, 3000);

      // recyclers.value = [
      //   {
      //     id: 1,
      //     company: "ABC Company",
      //     phoneNumber: "123456789",
      //     latitude: -6.293257,
      //     longitude: 106.746462,
      //     address: "Tangerang Selatan"
      //   },
      //   {
      //     id: 2,
      //     company: "XYZ Corporation",
      //     phoneNumber: "987654321",
      //     latitude: -6.325036,
      //     longitude: 106.766840,
      //     address: "Pisangan, Ciputat Timur, South Tangerang City, Banten"
      //   },
      //   {
      //     id: 3,
      //     company: "DEF Enterprises",
      //     phoneNumber: "555555555",
      //     latitude: -6.305214,
      //     longitude: 106.863645,
      //     address: "Jl. TB Simatupang, RT.5/RW.11, Gedong, Kec. Ps. Rebo, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13760"
      //   },
      //   {
      //     id: 4,
      //     company: "GHI Industries",
      //     phoneNumber: "999999999",
      //     latitude: -6.286814,
      //     longitude: 106.959993,
      //     address: "Jl. Cikunir Raya 7-28, RT.004/RW.011, Jatimekar, Kec. Jatiasih, Kota Bks, Jawa Barat 17422"
      //   },
      //   {
      //     id: 5,
      //     company: "JKL Limited",
      //     phoneNumber: "777777777",
      //     latitude: -6.253889,
      //     longitude: 107.008210,
      //     address: "PT. Rafisa Artha Mandiri, Graha Marhaban, Jl. Cut Mutia No.56, Margahayu, Kec. Bekasi Tim., Kota Bks, Jawa Barat 17113"
      //   }
      // ]

      // recyclers.value.forEach(item => {
      //   new mapboxgl.Marker()
      //     .setLngLat([
      //       item.longitude,
      //       item.latitude,
      //     ])
      //     .addTo(map);
      // })

      // // re-render polyline
      // if (
      //   createDelivery.pickupLocationMarker &&
      //   createDelivery.destinationLocationMarker
      // ) {
      //   drawPolyline();
      // }
    };

    const getWasteTypes = async () => {
      const res = await DeliveryService.getWasteTypes();
      wasteTypes.value = res.data.data;
    };

    const addWasteTypeSubmit = async () => {
      await DeliveryService.addWasteTypes({
        wasteTypeName: addWasteType.value,
      });
      getWasteTypes();
      showModalCreateWaste.value = false;
      toast.add({
        severity: "success",
        summary: "Create Waste Type",
        detail: "Successfully created waste type!",
        life: 3000,
      });
      addWasteSubType.value = "";
    };

    const getWasteSubTypes = async () => {
      const res = await DeliveryService.getWasteSubTypes(
        createDeliveryState.selectedWasteType
      );
      wasteSubTypes.value = res.data.data;
      showButtonCreateWasteSubTypes.value = true;
    };

    const addWasteSubTypeSubmit = async () => {
      await DeliveryService.addWasteSubTypes({
        wasteTypeId: createDeliveryState.selectedWasteType,
        wasteSubTypeName: addWasteSubType.value,
      });
      getWasteSubTypes();
      toast.add({
        severity: "success",
        summary: "Create Waste Sub Type",
        detail: "Successfully created waste sub type!",
        life: 3000,
      });
      showModalCreateWasteSubType.value = false;
      addWasteSubType.value = "";
    };

    return {
      FormatCurrency,
      isLoading,
      createDelivery$,
      submitted,
      goBack() {
        router.go(-1);
      },
      createDelivery,
      zoom,
      recyclers,
      showModalCreateWaste,
      suggestionPickupLocations,
      suggestionDestinationLocations,
      onCompletePickupClick,
      onCompleteDestinationClick,
      onCompleteDestinationLocation,
      delay,
      drawPolyline,
      getRecyclers,
      wasteTypes,
      addWasteType,
      addWasteTypeSubmit,
      getWasteSubTypes,
      showModalCreateWasteSubType,
      addWasteSubTypeSubmit,
      addWasteSubType,
      wasteSubTypes,
      showButtonCreateWasteSubTypes,
      isLoadingGetRecycler,
      producers,
    };
  },
};
</script>

<style>
#map {
  height: 400px;
  border-radius: 12px;
}
.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}
</style>
