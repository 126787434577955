<template>
  <div v-if="isLoading" class="text-center">
    <ProgressBar mode="indeterminate" style="height: 0.5em" />
  </div>
  <div v-else>
    <div class="card">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <Button
            icon="pi pi-arrow-left"
            class="p-button-info p-button-sm mr-2"
            @click="goBack()"
          />
          <div class="font-medium text-3xl text-900">Informasi Detail Penarikan</div>
        </div>

        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Withdraw ID</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ withdraw.withdrawId }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Seller ID</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ withdraw.sellerId }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Nama Lengkap</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ withdraw.firstName }} {{ withdraw.lastName }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Nama Perusahaan</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ withdraw.companyName }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Status</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <Tag :value="withdraw.status" :severity="getTagColor(withdraw.status)"></Tag>
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Nominal</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ FormatCurrency.toRupiah(withdraw.nominal) }}
            </div>
          </li>
          <li
            class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
            v-if="withdraw.transferProof != '-'"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Bank</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ withdraw.bank }}
            </div>
          </li>
          <li
            class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
            v-if="withdraw.transferProof != '-'"
          >
            <div class="text-500 w-6 md:w-2 font-medium">No. Rekening</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ withdraw.bankAccountNumber }}
            </div>
          </li>
          <li
            class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
            v-if="withdraw.transferProof != '-'"
          >
            <div class="text-500 w-6 md:w-2 font-medium">Bukti Pembayaran</div>
            <div class="text-900 md:flex-order-0 flex-order-1">
              <div class="card">
                <Image :src="withdraw.transferProof" alt="Image" width="250" preview />
              </div>
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Tanggal Dibuat</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ FormatDate.toDDMMYYYY(withdraw.createdAt) }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
import { useToast } from 'primevue/usetoast';
import WithdrawalService from '../../services/withdrawal/WithdrawalService';
import { useRoute, useRouter } from 'vue-router';
import FormatCurrency from '../../services/helpers/FormatCurrency';
import FormatDate from '../../services/helpers/FormatDate';
export default {
  setup() {
    onMounted(() => {
      getDetailWithdrawal(route.params.id);
    });

    const isLoading = ref(false);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const withdraw = ref({});

    const getDetailWithdrawal = async (id) => {
      try {
        isLoading.value = true;
        const response = await WithdrawalService.getDetail(id);
        if ((response.status = 201 && response.data.status == 'success')) {
          withdraw.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const getTagColor = (status) => {
      switch (status) {
        case 'On Progress':
          return 'warning';
        case 'Success':
          return 'success';
        default:
          return '';
      }
    };

    return {
      isLoading,
      toast,
      withdraw,
      getTagColor,
      FormatCurrency,
      FormatDate,
      goBack() {
        router.push({ name: 'withdrawalSeller' });
      },
    };
  },
};
</script>

<style></style>
