<template>
  <div class="card">
    <h5>Histogram Unit Ekonomi</h5>
    <Chart type="bar" :data="barData" :options="barOptions" />
  </div>
</template>

<script>
export default {
  props: {
    unitEconomicDataset: Array,
  },
  data() {
    return {
      barOptions: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                  }).format(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toLocaleString('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0,
                });
              },
            },
          },
        },
      },
    };
  },
  computed: {
    barData() {
      return {
        labels: this.unitEconomicDataset.map((d) => d.month),
        datasets: [
          {
            label: 'Sales',
            data: this.unitEconomicDataset.map((d) => d.sale),
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: 0.4,
          },
          {
            label: 'Expenses',
            data: this.unitEconomicDataset.map((d) => d.expense),
            fill: false,
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            tension: 0.4,
          },
          {
            label: 'Transport',
            data: this.unitEconomicDataset.map((d) => d.transport),
            fill: false,
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            tension: 0.4,
          },
        ],
      };
    },
  },
};
</script>

<style></style>
