<template>
  <Toast />
  <div
    class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div class="col-12 mt-5 xl:mt-0 text-center"></div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));
          "
        >
          <div class="text-center mb-5">
            <img src="layout/images/pituku-logo.png" alt="Image" height="50" class="mb-3" />
            <div class="text-900 text-3xl font-medium mb-3">Dasbor Admin</div>
            <span class="text-600 font-medium">Masuk untuk melanjutkan</span>
          </div>

          <form @submit.prevent="login()">
            <div class="w-full md:w-10 mx-auto">
              <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
              <InputText
                id="email1"
                v-model="email"
                type="text"
                class="w-full mb-3"
                placeholder="Email"
                style="padding: 1rem"
              />

              <label for="password1" class="block text-900 font-medium text-xl mb-2"
                >Kata Sandi</label
              >
              <Password
                id="password1"
                v-model="password"
                placeholder="Kata Sandi"
                :toggleMask="true"
                class="w-full mb-3"
                inputClass="w-full"
                inputStyle="padding:1rem"
                :feedback="false"
              ></Password>

              <Button
                label="Masuk"
                class="w-full p-3 text-xl"
                type="submit"
                :loading="isLoading"
              ></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import AuthService from '../services/authentication/AuthService';

export default {
  setup() {
    const router = useRouter();
    const toast = useToast();

    const email = ref();
    const password = ref();

    const isLoading = ref(false);

    const login = async () => {
      try {
        isLoading.value = true;
        const response = await AuthService.login(
          JSON.stringify({
            email: email.value,
            password: password.value,
          }),
        );
        if ((response.status = 201 && response.data.status == 'success')) {
          toast.add({
            severity: 'success',
            summary: response.data.status,
            detail: response.data.message,
          });
          sessionStorage.setItem('token', response.data.data.accessToken);
          isLoading.value = false;
          router.push({ name: 'dashboard' });
          router.go();
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      email,
      password,
      login,
      isLoading,
    };
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
