import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
  {
    path: '/',
    name: 'app',
    component: App,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('./components/Dashboard.vue'),
      },
      {
        path: '/seller/list',
        name: 'listSeller',
        component: () => import('./components/seller/ListSeller.vue'),
      },
      {
        path: '/seller/detail/:id',
        name: 'detailSeller',
        component: () => import('./components/seller/DetailSeller.vue'),
      },
      {
        path: '/seller/withdrawal/list',
        name: 'withdrawalSeller',
        component: () => import('./components/seller/WithdrawalSeller.vue'),
      },
      {
        path: '/seller/withdrawal/detail/:id',
        name: 'withdrawalSellerDetail',
        component: () => import('./components/seller/WithdrawalSellerDetail.vue'),
      },
      {
        path: '/waste/category',
        name: 'wasteCategory',
        component: () => import('./components/waste-info/WasteCategory.vue'),
      },
      {
        path: '/waste/category/add',
        name: 'addWasteCategory',
        component: () => import('./components/waste-info/AddWasteCategory.vue'),
      },
      {
        path: '/waste/sub-category',
        name: 'wasteSubCategory',
        component: () => import('./components/waste-info/WasteSubCategory.vue'),
      },
      {
        path: '/waste/sub-category/add',
        name: 'addWasteSubCategory',
        component: () => import('./components/waste-info/AddWasteSubCategory.vue'),
      },
      {
        path: '/buyer/list',
        name: 'buyerList',
        component: () => import('./components/buyer/ListBuyer.vue'),
      },
      {
        path: '/inquiry/detail/:id',
        name: 'detailInquiry',
        component: () => import('./components/inquiry/InquiryDetail.vue'),
      },
      {
        path: '/inquiry/list',
        name: 'inquiryList',
        component: () => import('./components/inquiry/InquiryList.vue'),
      },
      {
        path: '/inquiry/payment-confirm-buyer/:id',
        name: 'paymentConfirmBuyer',
        component: () => import('./components/inquiry/ConfirmPaymentBuyer.vue'),
      },
      {
        path: '/inquiry/payment-confirm-seller/:id',
        name: 'paymentConfirmSeller',
        component: () => import('./components/inquiry/ConfirmPaymentSeller.vue'),
      },
      {
        path: '/inquiry/shipment-confirm',
        name: 'shipmentConfirm',
        component: () => import('./components/inquiry/ConfirmShipment.vue'),
      },
      {
        path: '/inquiry/assign/:id',
        name: 'assignInquiry',
        component: () => import('./components/inquiry/InquiryAssign.vue'),
      },
      {
        path: '/inquiry/invoice/:id',
        name: 'addInvoice',
        component: () => import('./components/inquiry/AddInvoice.vue'),
      },
      {
        path: '/inquiry/invoice/edit/:id',
        name: 'editInvoice',
        component: () => import('./components/inquiry/EditInvoice.vue'),
      },
      {
        path: '/inquiry/shipment/:id',
        name: 'addShipment',
        component: () => import('./components/inquiry/AddShipment.vue'),
      },
      {
        path: '/article',
        name: 'listArticle',
        component: () => import('./components/article/ArticleList.vue'),
      },
      {
        path: '/article/create',
        name: 'createArticle',
        component: () => import('./components/article/CreateArticle.vue'),
      },
      {
        path: '/article/update/:id',
        name: 'updateArticle',
        component: () => import('./components/article/UpdateArticle.vue'),
      },
      {
        path: '/delivery',
        name: 'listDelivery',
        component: () => import('./components/delivery/DeliveryList.vue'),
      },
      {
        path: '/delivery/create',
        name: 'createDelivery',
        component: () => import('./components/delivery/CreateDelivery.vue'),
      },
      {
        path: '/delivery/update/:id',
        name: 'updateDelivery',
        component: () => import('./components/delivery/UpdateDelivery.vue'),
      },
      {
        path: '/driver',
        name: 'listDriver',
        component: () => import('./components/driver/DriverList.vue'),
      },
      {
        path: '/driver/create',
        name: 'createDriver',
        component: () => import('./components/driver/CreateDriver.vue'),
      },
      {
        path: '/driver/update/:id',
        name: 'updateDriver',
        component: () => import('./components/driver/UpdateDriver.vue'),
      },
      {
        path: '/driver/d/:id',
        name: 'detailDriver',
        component: () => import('./components/driver/DetailDriver.vue'),
      },
      {
        path: '/recycler',
        name: 'listRecycler',
        component: () => import('./components/recycler/RecyclerList.vue'),
      },
      {
        path: '/recycler/create',
        name: 'createRecycler',
        component: () => import('./components/recycler/CreateRecycler.vue'),
      },
      {
        path: '/producer',
        name: 'listProducer',
        component: () => import('./components/producer/ProducerList.vue'),
      },
      {
        path: '/producer',
        name: 'createProducer',
        component: () => import('./components/producer/CreateProducer.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLogin = sessionStorage.getItem('token');

  if (to.name === 'login' && isLogin) {
    next({ name: 'dashboard' });
  } else if (to.name !== 'login' && to.name !== 'notfound' && to.name !== 'error' && !isLogin) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
