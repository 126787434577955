<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" />
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <!-- <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-calendar"></i>
          <span>Events</span>
        </button>
      </li>
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-cog"></i>
          <span>Settings</span>
        </button>
      </li> -->
      <li>
        <button class="p-link layout-topbar-button" @click="toggle">
          <i class="pi pi-user"></i>
          <span>Profile</span>
        </button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();

    const menu = ref();
    const items = ref([
      {
        label: 'Account',
        items: [
          // {
          //   label: 'Change Password',
          //   icon: 'pi pi-refresh',
          //   command: () => {},
          // },
          // {
          //   label: 'Edit Account',
          //   icon: 'pi pi-pencil',
          //   command: () => {},
          // },
          {
            label: 'Logout',
            icon: 'pi pi-times',
            command: () => {
              sessionStorage.removeItem('token');
              if (!sessionStorage.getItem('token')) {
                router.push({ name: 'login' });
              }
            },
          },
        ],
      },
    ]);

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    return { items, menu, toggle };
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? 'layout/images/pituku-logo.png'
        : 'layout/images/pituku-logo.png';
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
