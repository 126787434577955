<template>
  <div class="card">
    <h5>Grafik Pesanan</h5>
    <p>{{ FormatDate.toMonthYear() }}</p>
    <Chart type="line" :data="lineData" :options="lineOptions" />
  </div>
</template>

<script>
import FormatDate from '../../services/helpers/FormatDate';
export default {
  props: {
    inquiryDataset: Array,
  },
  data() {
    return {
      lineOptions: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Tanggal',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Jumlah Pesanan',
            },
          },
        },
      },
      FormatDate,
    };
  },
  computed: {
    lineData() {
      return {
        labels: this.inquiryDataset.map((d) => FormatDate.toDayOnly(d.date)),
        datasets: [
          {
            label: 'Pesanan',
            data: this.inquiryDataset.map((d) => d.total),
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
          },
        ],
      };
    },
  },
};
</script>

<style></style>
