<template>
  <h5>Upcoming Withdrawal</h5>
  <DataTable
    :lazy="true"
    :scrollable="true"
    :value="WithdrawalList"
    @page="onPage($event)"
    :rows="10"
    :paginator="true"
    responsiveLayout="scroll"
    removableSort
    :loading="isLoading"
    :totalRecords="totalItems"
  >
    <template #empty> Permintaan penarikan tidak ditemukan. </template>
    <Column style="min-width: 14rem" header="Withdraw ID">
      <template #body="{ data }"> {{ data.withdrawId.substr(0, 15) }}... </template>
    </Column>
    <Column style="min-width: 14rem" header="Seller ID">
      <template #body="{ data }"> {{ data.sellerId.substr(0, 15) }}... </template>
    </Column>
    <Column style="min-width: 14rem" header="Nama Lengkap">
      <template #body="{ data }"> {{ data.firstName }} {{ data.lastName }} </template>
    </Column>
    <Column style="min-width: 14rem" field="companyName" header="Nama Perusahaan"></Column>
    <Column style="min-width: 14rem" header="Nominal">
      <template #body="{ data }">
        {{ FormatCurrency.toRupiah(data.nominal) }}
      </template>
    </Column>
    <Column style="min-width: 14rem" header="Tanggal Dibuat">
      <template #body="{ data }">
        {{ FormatDate.toDDMMYYYY(data.createdAt) }}
      </template>
    </Column>
    <Column style="min-width: 14rem" header="Status" frozen alignFrozen="right">
      <template #body="{ data }">
        <Tag :value="data.status" :severity="getTagColor(data.status)"></Tag>
      </template>
    </Column>
    <Column header="Opsi" style="min-width: 8rem" frozen alignFrozen="right">
      <template #body="{ data }">
        <Button
          type="button"
          icon="pi pi-ellipsis-h"
          @click="toggle(data.withdrawId, $event)"
        ></Button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </template>
    </Column>
  </DataTable>
  <Toast />
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
import { useToast } from 'primevue/usetoast';
import WithdrawalService from '../../services/withdrawal/WithdrawalService';

import FormatCurrency from '../../services/helpers/FormatCurrency';
import FormatDate from '../../services/helpers/FormatDate';

export default {
  setup() {
    onMounted(async () => {
      getAllWithdrawal();
    });

    const isLoading = ref(false);
    const toast = useToast();

    const withdrawId = ref(null);

    const menu = ref();
    const currentPage = ref(1);
    const totalPages = ref(null);
    const totalItems = ref(null);

    const items = ref([
      {
        label: 'Action',
        items: [
          {
            label: 'Konfirmasi Pembayaran',
            icon: 'pi pi-search',
            to: {
              name: 'paymentConfirmSeller',
              params: {
                id: withdrawId,
              },
            },
          },
          {
            label: 'Detail',
            icon: 'pi pi-eye',
            to: {
              name: 'withdrawalSellerDetail',
              params: {
                id: withdrawId,
              },
            },
          },
        ],
      },
    ]);

    const toggle = (withdraw, event) => {
      withdrawId.value = withdraw;
      menu.value.toggle(event);
    };

    const WithdrawalList = ref([]);

    const getAllWithdrawal = async () => {
      try {
        isLoading.value = true;
        const response = await WithdrawalService.getList(currentPage.value);
        if ((response.status = 201 && response.data.status == 'success')) {
          WithdrawalList.value = response.data.data;
          currentPage.value = response.data.currentPage;
          totalPages.value = response.data.totalPages;
          totalItems.value = response.data.totalItems;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const onPage = (event) => {
      currentPage.value = event.page + 1;
      getAllWithdrawal();
    };

    const getTagColor = (status) => {
      switch (status) {
        case 'On Progress':
          return 'warning';
        case 'Dalam Perjalanan':
          return 'warning';
        case 'Dibayar':
          return 'success';
        case 'Berhasil Dikirim':
          return 'success';
        default:
          return '';
      }
    };

    return {
      isLoading,
      toast,
      WithdrawalList,
      FormatCurrency,
      FormatDate,
      items,
      menu,
      toggle,
      getTagColor,
      onPage,
      totalItems,
    };
  },
};
</script>

<style></style>
