<template>
  <DataTable
    :lazy="true"
    :scrollable="true"
    :value="InquiryList"
    :rows="10"
    :paginator="true"
    responsiveLayout="scroll"
    removableSort
    :loading="isLoading"
    @page="onPage($event)"
    :totalRecords="totalItems"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    currentPageReportTemplate="Menampilkan {first} - {last} dari {totalRecords} baris"
    :rowsPerPageOptions="[10, 25, 50]"
    :rowHover="true"
    dataKey="id"
    class="p-datatable-sellers"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <h5 class="m-0">Konfirmasi Pengiriman</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            class="w-full"
            v-model="query"
            placeholder="Keyword Search"
            @keydown.enter="handleSearch"
          />
        </span>
      </div>
    </template>
    <template #empty> Pesanan tidak ditemukan. </template>
    <Column style="min-width: 14rem" header="Jenis Limbah">
      <template #body="{ data }">
        {{ data.wasteSubCategoryName.join(", ") }}
      </template>
    </Column>
    <Column
      style="min-width: 14rem"
      field="companyName"
      header="Nama Perusahaan"
    ></Column>
    <Column
      style="min-width: 14rem"
      field="wasteBank"
      header="Mitra Pituku"
    ></Column>
    <Column
      style="min-width: 14rem"
      field="wasteBankCity"
      header="Kota Mitra Pituku"
    ></Column>
    <Column
      style="min-width: 14rem"
      field="city"
      header="Kota Perusahaan"
    ></Column>
    <Column style="min-width: 14rem" header="Kuantitas">
      <template #body="{ data }"> {{ data.totalQuantity }}kg </template>
    </Column>
    <!-- <Column style="min-width: 14rem" header="Harga Minimal">
      <template #body="{ data }">
        {{ FormatCurrency.toRupiah(data.priceMinimum) }}
      </template>
    </Column>
    <Column style="min-width: 14rem" header="Harga Maksimum">
      <template #body="{ data }">
        {{ FormatCurrency.toRupiah(data.priceMaximum) }}
      </template>
    </Column>
    <Column style="min-width: 14rem" header="Harga Tetap">
      <template #body="{ data }">
        {{ FormatCurrency.toRupiah(data.fixedPrice) }}
      </template>
    </Column> -->
    <Column style="min-width: 14rem" header="Tanggal Pemesanan">
      <template #body="{ data }">
        {{ FormatDate.toDDMMYYYY(data.orderDate) }}
      </template>
    </Column>
    <Column style="min-width: 14rem" header="Tanggal Dibuat">
      <template #body="{ data }">
        {{ FormatDate.toDDMMYYYY(data.createdAt) }}
      </template>
    </Column>
    <Column
      style="min-width: 14rem"
      header="Status Pembayaran"
      frozen
      alignFrozen="right"
    >
      <template #body="{ data }">
        <Tag
          :value="data.paymentStatus"
          :severity="getTagColor(data.paymentStatus)"
        ></Tag>
      </template>
    </Column>
    <Column
      style="min-width: 14rem"
      header="Status Pengiriman"
      frozen
      alignFrozen="right"
    >
      <template #body="{ data }">
        <Tag
          :value="data.shipmentStatus"
          :severity="getTagColor(data.shipmentStatus)"
        ></Tag>
      </template>
    </Column>
    <Column header="Opsi" style="min-width: 8rem" frozen alignFrozen="right">
      <template #body="{ data }">
        <Button
          type="button"
          icon="pi pi-ellipsis-h"
          @click="toggle(data.id, $event)"
        ></Button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </template>
    </Column>
  </DataTable>
  <Toast />
  <Dialog
    :header="title"
    v-model:visible="displayConfirmation"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>{{ message }}</span>
    </div>

    <template #footer>
      <Button
        :loading="isLoading"
        label="Ya"
        icon="pi pi-check"
        @click="confirmation()"
        class="p-button"
        autofocus
      />
      <Button
        label="Tidak"
        icon="pi pi-times"
        @click="closeConfirmation()"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import InquiryService from "../../services/inquiry/InquiryService";

import FormatCurrency from "../../services/helpers/FormatCurrency";
import FormatDate from "../../services/helpers/FormatDate";

export default {
  setup() {
    onMounted(async () => {
      getAllInquiry();
    });
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(null);
    const totalItems = ref(null);
    const query = ref("");

    const isLoading = ref(false);
    const toast = useToast();

    const inquiryId = ref(null);

    const menu = ref();

    const items = ref([
      {
        label: "Action",
        items: [
          {
            label: "Tandai Selesai",
            icon: "pi pi-check",
            command: () => {
              openConfirmation(
                "Konfirmasi Inquiry Selesai",
                "Apakah anda yakin untuk mengonfirmasi inquiry ini telah selesai?"
              );
            },
          },
          {
            label: "Detail",
            icon: "pi pi-eye",
            to: { name: "detailInquiry", params: { id: inquiryId } },
          },
        ],
      },
    ]);

    const toggle = (id, event) => {
      menu.value.toggle(event);
      inquiryId.value = id;
    };

    const InquiryList = ref([]);

    const getAllInquiry = async () => {
      try {
        isLoading.value = true;
        const response = await InquiryService.getInquiryOnTheWay(
          currentPage.value,
          pageSize.value,
          query.value
        );
        if ((response.status = 201 && response.data.status == "success")) {
          InquiryList.value = response.data.data;
          currentPage.value = response.data.currentPage;
          totalPages.value = response.data.totalPages;
          totalItems.value = response.data.totalItems;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const handleSearch = (event) => {
      if (event.keyCode === 13) {
        getAllInquiry()
      }
    };

    const onPage = (event) => {
      pageSize.value = event.rows;
      currentPage.value = event.page + 1;
      getAllInquiry();
    };

    const getTagColor = (status) => {
      switch (status) {
        case "Dipesan":
          return "danger";
        case "Belum Dikirim":
          return "danger";
        case "Menunggu Pembayaran":
          return "warning";
        case "Dalam Perjalanan":
          return "warning";
        case "Dibayar":
          return "success";
        case "Berhasil Dikirim":
          return "success";
        default:
          return "";
      }
    };

    /* Dialog Confirmation */
    const displayConfirmation = ref(false);

    const modal = reactive({
      title: "",
      message: "",
    });

    const openConfirmation = (title, message) => {
      displayConfirmation.value = true;
      modal.title = title;
      modal.message = message;
    };

    const closeConfirmation = () => {
      displayConfirmation.value = false;
    };
    /* Akhir Dialog Confirmation */

    const confirmation = async () => {
      try {
        isLoading.value = true;
        const response = await InquiryService.confirmation(inquiryId.value);
        if ((response.status = 201 && response.data.status == "success")) {
          isLoading.value = false;
          closeConfirmation();
          await getAllInquiry();
          toast.add({
            severity: "success",
            summary: "Success",
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
            life: 3000,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
            life: 3000,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
            life: 3000,
          });
        }
        isLoading.value = false;
        closeConfirmation();
      }
    };
    return {
      isLoading,
      toast,
      InquiryList,
      FormatCurrency,
      FormatDate,
      items,
      menu,
      toggle,
      getTagColor,
      displayConfirmation,
      openConfirmation,
      closeConfirmation,
      confirmation,
      inquiryId,
      totalPages,
      totalItems,
      onPage,
      query,
      handleSearch,
      ...toRefs(modal),
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-sellers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog {
  min-width: 6rem;
}
</style>
