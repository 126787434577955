import API from '../API';

class InquiryService {
  getList(page, paymentStatus, shipmentStatus, assigned, size, search) {
    console.log(search)
    return API.get(`/api/inquiries?page=${page}${paymentStatus}${shipmentStatus}${assigned}&size=${size}&search=${search.length > 1 ? search : ''}`);
  }
  getDetail(id) {
    return API.get(`/api/inquiries/${id}`);
  }
  assignWasteBank(data) {
    return API.post('/api/inquiries/assign-waste-bank', data);
  }
  confirmation(inquiryId) {
    return API.post(`/api/inquiries/${inquiryId}/confirm-shipment`);
  }
  getListAllInquiry() {
    return API.get(`/api/inquiries?size=10000`);
  }
  getInquiryOnTheWay(page, size, search) {
    return API.get(`/api/inquiries?page=${page}&shipmentStatus=Dalam Perjalanan&size=${size}&search=${search.length > 1 ? search : ''}`);
  }
}

export default new InquiryService();
