<template>
  <DataTable
    :value="wasteList"
    :paginator="true"
    class="p-datatable-wastes"
    :rows="5"
    dataKey="id"
    :rowHover="true"
    :loading="isLoading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Menampilkan {first} - {last} dari {totalRecords} baris"
    responsiveLayout="scroll"
    removableSort
    v-model:filters="filters"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <h5 class="m-0">Daftar Kategori Sampah</h5>
        <div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Pencarian…"
            />
          </span>
          <router-link :to="{ name: 'addWasteCategory' }">
            <Button
              label="Buat Baru"
              icon="pi pi-plus"
              class="p-button-success ml-2"
            />
          </router-link>
        </div>
      </div>
    </template>
    <template #empty> No wastes found. </template>
    <Column
      field="id"
      header="ID Kategori Sampah"
      sortable
      style="min-width: 8rem"
    >
    </Column>
    <Column
      field="categoryName"
      header="Nama Kategori Sampah"
      sortable
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <p v-if="wasteCategoryId != data.id">{{ data.categoryName }}</p>
        <InputText
          v-if="isEditing && wasteCategoryId == data.id"
          v-model="wasteCategoryName"
          @keyup.enter="updateWasteCategory()"
        />
      </template>
    </Column>
    <Column header="Opsi" style="max-width: 6rem" frozen alignFrozen="right">
      <template #body="{ data }">
        <Button
          v-if="wasteCategoryId != data.id"
          type="button"
          icon="pi pi-pencil"
          @click="edit(data.id, data.categoryName)"
        >
        </Button>
        <div class="flex gap-2">
          <Button
            v-if="isEditing && wasteCategoryId == data.id"
            type="button"
            icon="pi pi-check"
            @click="updateWasteCategory()"
          >
          </Button>
          <Button
            v-if="isEditing && wasteCategoryId == data.id"
            class="p-button-danger"
            type="button"
            icon="pi pi-times"
            @click="closeEdit(data.id)"
          >
          </Button>
        </div>
      </template>
    </Column>
    <Toast />
  </DataTable>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import WasteService from "../../services/waste-info/WasteService";
import { FilterMatchMode } from "primevue/api";

export default {
  setup() {
    onMounted(async () => {
      getAllWaste();
    });

    const isLoading = ref(false);
    const toast = useToast();
    const menu = ref();
    const isEditing = ref(false);

    const wasteList = ref([]);
    const wasteCategoryId = ref(0);
    const wasteCategoryName = ref("");

    const items = ref([
      {
        label: "Opsi",
        items: [
          {
            label: "Edit",
            icon: "pi pi-check",
            to: {
              name: "updateWasteCategory",
              params: { id: wasteCategoryId },
            },
          },
        ],
      },
    ]);

    const edit = (id, name) => {
      wasteCategoryId.value = id;
      wasteCategoryName.value = name;
      isEditing.value = true;
    };

    const closeEdit = () => {
      wasteCategoryId.value = 0;
      isEditing.value = false;
    };

    const getAllWaste = async () => {
      try {
        isLoading.value = true;
        const response = await WasteService.getList();
        if ((response.status = 201 && response.data.status == "success")) {
          wasteList.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const updateWasteCategory = () => {
      WasteService.updateCategory({
        id: wasteCategoryId.value,
        categoryName: wasteCategoryName.value,
      }).then((res) => {
        console.log(res.data);
      }).catch(err => {
        if (err.response) {
          toast.add({
            severity: "error",
            summary: err.response.data.status,
            detail: err.response.data.message,
          });
        } else if (err.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: err.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
          });
        }
      }).finally(() => {
        wasteCategoryId.value = 0;
        isEditing.value = false;
        getAllWaste()
      })
    };

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    return {
      isLoading,
      toast,
      wasteList,
      filters,
      items,
      edit,
      menu,
      isEditing,
      wasteCategoryId,
      closeEdit,
      wasteCategoryName,
      updateWasteCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}
::v-deep(.p-datatable.p-datatable-wastes) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}
</style>
