<template>
  <div v-if="isLoading" class="text-center">
    <ProgressBar mode="indeterminate" style="height: 0.5em" />
  </div>
  <div v-else class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-info p-button-sm mr-2"
          @click="goBack()"
        />
        <div class="font-medium text-3xl text-900">
          Seller Detail Information
        </div>
      </div>

      <ul class="list-none p-0 m-0">
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">Nama Lengkap</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ seller.firstName }} {{ seller.lastName }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">Email</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ seller.email }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">Perusahaan</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ seller.companyName }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">No. HP</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ seller.phoneNumber }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">Alamat</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ seller.street }}, {{ seller.city }}, {{ seller.province }}-{{
              seller.postalCode
            }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">Status</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Tag
              :value="seller.status"
              :severity="getTagColor(seller.status)"
            ></Tag>
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium">Rata-rata Penjualan</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ seller.averageSales }} ton
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-medium mb-2">
            Waste Repository
          </div>
        </li>
      </ul>

      <div class="w-full">
        <div
          class="card w-full"
          v-for="waste in seller.wasteRepository"
          :key="waste.wasteSubCategoryCode"
        >
          <div class="flex flex-row flex-wrap card-container">
            <div class="waste-repository-info w-4">
              <div class="waste-row-data flex flex-row p-2">
                <div class="waste-info w-5 text-500 font-medium">
                  Kode Limbah
                </div>
                <div class="waste-data w-5 text-900">
                  {{ waste.wasteSubCategoryCode }}
                </div>
              </div>
              <div class="waste-row-data flex flex-row p-2">
                <div class="waste-info w-5 text-500 font-medium">
                  Jenis Limbah
                </div>
                <div class="waste-data w-5 text-900">
                  {{ waste.wasteSubCategoryName }}
                </div>
              </div>
              <div class="waste-row-data flex flex-row p-2">
                <div class="waste-info w-5 text-500 font-medium">Stok</div>
                <div class="waste-data w-5 text-900">{{ waste.stock }}kg</div>
              </div>
              <div class="waste-row-data flex flex-row p-2">
                <div class="waste-info w-5 text-500 font-medium">
                  Average Price
                </div>
                <div class="waste-data w-5 text-900">
                  {{ FormatCurrency.toRupiah(waste.averagePrice) }}
                </div>
              </div>
            </div>
            <div
              class="flex waste-repository-image w-8 align-self-center align-items-center justify-content-center"
            >
              <div v-if="waste.photos !== null" class="grid">
                <div v-for="(photo, index) in waste.photos" class="col" v-bind:key="index">
                  <Image
                    imageClass="border-round"
                    :src="photo"
                    imageStyle="max-width: 100%; aspect-ratio: 16 / 9"
                    preview="true"
                  />
                </div>
                <div v-if="waste.video !== null" class="col video-container">
                  <video controls>
                    <source :src="waste.video" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div v-if="waste.photos === null" class="grid">
                <div class="col">
                  <!-- <img
                    class="border-round"
                    src="https://images.unsplash.com/photo-1562077981-4d7eafd44932?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    style="max-width: 100%; aspect-ratio: 16 / 9"
                  /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import SellerService from "../../services/seller/SellerService";
import { useRoute, useRouter } from "vue-router";
import FormatCurrency from "../../services/helpers/FormatCurrency";
import Image from 'primevue/image';

export default {
  setup() {
    onMounted(async () => {
      getDetailSeller(route.params.id);
    });

    const isLoading = ref(false);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const seller = ref({});

    const getDetailSeller = async (id) => {
      try {
        isLoading.value = true;
        const response = await SellerService.getDetail(id);
        if ((response.status = 201 && response.data.status == "success")) {
          seller.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const getTagColor = (status) => {
      switch (status) {
        case "In Review":
          return "warning";
        case "Approved":
          return "success";
        case "Declined":
          return "danger";
        default:
          return "";
      }
    };

    return {
      isLoading,
      toast,
      seller,
      getTagColor,
      FormatCurrency,
      goBack() {
        router.go(-1);
      },
      Image
    };
  },
};
</script>

<style></style>
