<template>
  <DataTable :value="producerList" :paginator="true" class="p-datatable-wastes" :rows="5" dataKey="id" :rowHover="true"
    :loading="isLoading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]" currentPageReportTemplate="Menampilkan {first} - {last} dari {totalRecords} baris"
    responsiveLayout="scroll" removableSort v-model:filters="filters">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <h5 class="m-0">Daftar Producer</h5>
        <div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Pencarian…" />
          </span>
          <router-link :to="{ name: 'createProducer' }">
            <Button label="Buat Baru" icon="pi pi-plus" class="p-button-success ml-2" />
          </router-link>
        </div>
      </div>
    </template>
    <template #empty> No recycler found. </template>
    <Column field="id" header="ID" sortable style="max-width: 2rem" />
    <Column field="companyName" header="Nama Perusahaan" sortable style="max-width: 4rem" />
    <Column field="address" header="Alamat" sortable style="max-width: 10rem" />
    <Column header="Opsi" style="max-width: 2rem" frozen alignFrozen="right">
      <template #body="{ data }">
        <Button type="button" icon="pi pi-ellipsis-h" @click="toggle(data.id, $event)"></Button>
        <Menu ref="menu" :model="items" :popup="true" />
      </template>
    </Column>
    <Toast />
  </DataTable>

  <Dialog v-model:visible="showModalDelete" modal header="Hapus artikel ini ?" :style="{ width: '50vw' }">
    <p>Jika Ya, maka recycler akan dihapus.</p>
    <template #footer>
      <Button label="Batalkan" class="p-button-secondary" icon="pi pi-times" @click="showModalDelete = false" text />
      <Button label="Ya, hapus" icon="pi pi-check" class="p-button-danger" @click="deleteArticle()" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import DeliveryService from '../../services/delivery/DeliveryService';

export default {
  setup() {
    onMounted(async () => {
      getProducers();
    });

    const isLoading = ref(false);
    const toast = useToast();
    const menu = ref();
    const articleId = ref(0);

    const articleList = ref([]);
    const producerList = ref([]);
    const showModalDelete = ref(false);

    const items = ref([
      {
        label: "Opsi",
        items: [
          {
            label: "Edit",
            icon: "pi pi-check",
            to: { name: "updateArticle", params: { id: articleId } },
          },
          {
            label: "Hapus",
            icon: "pi pi-times",
            command: () => {
              showModalDelete.value = true;
            },
          },
        ],
      },
    ]);

    const toggle = (id, event) => {
      menu.value.toggle(event);
      articleId.value = id;
    };

    const getProducers = async () => {
      DeliveryService.getProducers().then(res => {
        producerList.value = res.data.data;
      }).catch(error => {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
      }).finally(() => {
        isLoading.value = false;
      })
    };

    const deleteArticle = () => {
      isLoading.value = true;
    };

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    return {
      isLoading,
      toast,
      articleList,
      filters,
      items,
      toggle,
      menu,
      showModalDelete,
      deleteArticle,
      producerList
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-wastes) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }
}
</style>
