<template>
  <div v-if="isLoading" class="text-center">
    <ProgressBar mode="indeterminate" style="height: 0.5em" />
  </div>
  <div v-else>
    <div class="card">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <Button
            icon="pi pi-arrow-left"
            class="p-button-info p-button-sm mr-2"
            @click="goBack()"
          />
          <div class="font-medium text-3xl text-900">
            Informasi Detail Pesanan
          </div>
        </div>

        <ul class="list-none p-0 m-0">
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Id</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.id }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">ID Mitra Pituku</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.wasteBankId }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Mitra Pituku</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.wasteBank }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">ID Pembeli</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.buyerId }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Nama Pembeli</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.buyerName }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">No. HP Pembeli</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.buyerPhoneNumber }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Kuantitas</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-if="inquiry.inquiryItems">
              {{ inquiry.inquiryItems.map(x => x.quantity).reduce((prev, curr) => prev+curr) }}kg
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Tanggal Pemesanan</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ FormatDate.toDDMMYYYY(inquiry.orderDate) }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Status Pembayaran</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <Tag
                :value="inquiry.paymentStatus"
                :severity="getTagColor(inquiry.paymentStatus)"
              ></Tag>
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Status Pengiriman</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <Tag
                :value="inquiry.shipmentStatus"
                :severity="getTagColor(inquiry.shipmentStatus)"
              ></Tag>
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Tanggal dibuat</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ FormatDate.toDDMMYYYY(inquiry.createdAt) }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Deskripsi</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ inquiry.description }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Informasi Waste-->
    <div class="card">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <div class="w-6 font-medium text-3xl text-900">Daftar Sampah</div>
          <div class="w-6 flex justify-content-end">
            <router-link
              :to="{ name: 'paymentConfirmBuyer' }"
              v-if="inquiry.paymentStatus == 'Menunggu Pembayaran'"
            >
              <Button
                icon="pi pi-dollar"
                class="p-button-success p-button-sm mr-2"
                label="Konfirmasi Pembayaran"
              />
            </router-link>
          </div>
        </div>
        <Accordion>
          <AccordionTab
            v-for="(inquiryItem, index) in inquiry.inquiryItems"
            v-bind:key="index"
          >
            <template #header>
              <div class="flex flex-row w-full">
                <h5 class="my-auto">{{ inquiryItem.wasteSubCategoryName }}</h5>
              </div>
            </template>
            <ul class="list-none p-0 m-0">
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">
                  {{ inquiryItem.wasteSubCategoryName }}
                </div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ inquiryItem.wasteSubCategoryName }}
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Kuantitas</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ inquiryItem.quantity }}Kg
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Harga Minimum</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ FormatCurrency.toRupiah(inquiryItem.priceMinimum) }}
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Harga Maximum</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ FormatCurrency.toRupiah(inquiryItem.priceMaximum) }}
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Harga Tetap</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ FormatCurrency.toRupiah(inquiryItem.fixedPrice) }}
                </div>
              </li>
            </ul>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <!-- Akhir Informasi Waste -->

    <!-- Informasi Invoice-->
    <div class="card">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <div class="w-6 font-medium text-3xl text-900">Informasi Invoice</div>
          <div class="w-6 flex justify-content-end">
            <Button
                icon="pi pi-dollar"
                class="p-button-success p-button-sm mr-2"
                label="Konfirmasi Pembayaran"
                @click="openPaymentLink(invoice.paymentLink)"
              />
            <!-- <router-link
              :to="{ name: 'paymentConfirmBuyer' }"
              v-if="inquiry.paymentStatus == 'Menunggu Pembayaran'"
            >
              <Button
                icon="pi pi-dollar"
                class="p-button-success p-button-sm mr-2"
                label="Konfirmasi Pembayaran Manual"
              />
            </router-link> -->
            <Button
              v-if="invoice"
              icon="pi pi-file-pdf"
              class="p-button-danger p-button-sm mr-2"
              label="Export PDF"
              @click="downloadInvoice"
            />
            <router-link :to="{ name: 'editInvoice' }" v-if="invoice">
              <Button
                icon="pi pi-pencil"
                class="p-button-warning p-button-sm"
              />
            </router-link>
            <router-link :to="{ name: 'addInvoice' }" v-if="!invoice">
              <Button icon="pi pi-plus" class="p-button-info p-button-sm" />
            </router-link>
          </div>
        </div>
        <ul class="list-none p-0 m-0" v-if="invoice">
          <li
            v-if="invoice.invoiceNumber"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">No. Invoice</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ invoice.invoiceNumber }}
            </div>
          </li>
          <li
            v-if="invoice.invoiceDate"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Tanggal Invoice</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatDate.toDDMMYYYY(invoice.invoiceDate) }}
            </div>
          </li>
          <li
            v-if="invoice.invoiceDueDate"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">
              Batas Waktu Invoice
            </div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{
                invoice.invoiceDueDate == "COD"
                  ? "COD (Cash On Delivery)"
                  : FormatDate.toDDMMYYYY(invoice.invoiceDueDate)
              }}
            </div>
          </li>
          <li
            v-if="inquiry.fixedPrice"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Harga Tetap</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatCurrency.toRupiah(inquiry.fixedPrice) }}
            </div>
          </li>
          <li
            v-if="invoice.subtotal"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Sub Total</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatCurrency.toRupiah(invoice.subtotal) }}
            </div>
          </li>
          <li
            v-if="invoice.tax"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Pajak</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatCurrency.toRupiah(invoice.tax) }}
            </div>
          </li>
          <li
            v-if="invoice.discount"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Diskon</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatCurrency.toRupiah(invoice.discount) }}
            </div>
          </li>
          <li
            v-if="invoice.shippingCost"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Biaya Pengiriman</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatCurrency.toRupiah(invoice.shippingCost) }}
            </div>
          </li>
          <li
            v-if="invoice.total"
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-900 w-6 md:w-2 font-bold">Total</div>
            <div
              class="
                text-900
                font-bold
                w-full
                md:w-10 md:flex-order-0
                flex-order-1
              "
            >
              {{ FormatCurrency.toRupiah(invoice.total) }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Akhir Informasi Invoice -->

    <!-- Informasi Pembayaran Pembeli -->
    <div class="card" v-if="buyerPayment">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <div class="w-6 font-medium text-3xl text-900">
            Informasi Pembayaran Pembeli
          </div>
        </div>
        <ul class="list-none p-0 m-0">
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Metode Pembayaran</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              <p>Transfer</p>
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Bank</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              <div>
                <img
                  :src="buyerPayment.bankIcon"
                  alt="bankIcon"
                  height="16"
                  style="display: inline-block; vertical-align: middle"
                />
              </div>
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">No. Rekening</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ buyerPayment.bankAccountNumber }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Akhir Informasi Pembayaran Pembeli -->

    <!-- Informasi Pembayaran Seller/Mitra -->
    <div class="card" v-if="sellerPayment">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <div class="font-medium text-3xl text-900">
            Informasi Pembayaran Seller/Mitra
          </div>
        </div>
        <ul class="list-none p-0 m-0">
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Metode Pembayaran</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              <p>Transfer</p>
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Bank Tujuan</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              <div>
                <img
                  :src="sellerPayment.bankIcon"
                  alt="bankIcon"
                  height="16"
                  style="display: inline-block; vertical-align: middle"
                />
              </div>
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">No. Rekening</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ sellerPayment.bankAccountNumber }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-900 w-6 md:w-2 font-bold">Nominal</div>
            <div
              class="
                text-900
                font-bold
                w-full
                md:w-10 md:flex-order-0
                flex-order-1
              "
            >
              {{ FormatCurrency.toRupiah(sellerPayment.nominal) }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Bukti Pembayaran</div>
            <div class="text-900 md:flex-order-0 flex-order-1">
              <div class="card">
                <Image
                  :src="sellerPayment.transferProof"
                  alt="Image"
                  width="250"
                  preview
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Akhir Informasi Pembayaran Seller/Mitra -->

    <!-- Informasi Pengiriman -->
    <div class="card">
      <div class="surface-section">
        <div class="flex align-items-center mb-3">
          <div class="w-6 font-medium text-3xl text-900">
            Informasi Pengiriman
          </div>
          <div class="w-6 flex justify-content-end">
            <Button
              v-if="shipment"
              icon="pi pi-file-pdf"
              class="p-button-danger p-button-sm mr-2"
              label="Export PDF"
              :loading="isShipmentDownload"
              @click="downloadShipment"
            />
            <a v-if="shipment" :href="templateMessage" target="_blank">
              <Button
                icon="pi pi-whatsapp"
                class="p-button-success p-button-sm mr-2"
                label="Bagikan"
              />
            </a>

            <router-link :to="{ name: 'addShipment' }">
              <Button icon="pi pi-pencil" class="p-button-info p-button-sm" />
            </router-link>
          </div>
        </div>
        <ul class="list-none p-0 m-0" v-if="shipment">
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">
              Tanggal Pengiriman
            </div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatDate.toDDMMYYYY(shipment.sentDate) }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Dikirim Dari</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ shipment.senderStreetName }} {{ shipment.senderCity }}
              {{ shipment.senderProvince }}
              {{ shipment.senderPostalCode }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Kendaraan</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ shipment.vehicle }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Plat Nomor</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ shipment.numberPlate }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Supir</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ shipment.driver }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Nomor HP</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ shipment.phoneNumber }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">
              Tanggal Estimasi Sampai
            </div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ FormatDate.toDDMMYYYY(shipment.receiveDateEstimation) }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Alamat Tujuan</div>
            <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
              {{ shipment.recipientStreet }} {{ shipment.recipientCity }}
              {{ shipment.recipientProvince }}
              {{ shipment.recipientPostalCode }}
            </div>
          </li>
          <li
            class="
              flex
              align-items-center
              py-3
              px-2
              border-top-1
              surface-border
              flex-wrap
            "
          >
            <div class="text-500 w-6 md:w-2 font-medium">Dokumentasi</div>
            <div class="text-900 md:flex-order-0 flex-order-1">
              <div
                class="card"
                v-for="(img, id) in shipment.documentation"
                :key="id"
              >
                <Image :src="img" alt="Image" width="250" preview />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Akhir Informasi Pengiriman -->

    <!-- Invoice Download -->
    <div v-if="invoice" id="element-to-convert" class="card">
      <div class="surface-section">
        <div class="flex align-items-center mb-3 justify-content-between">
          <div class="w-6">
            <img
              src="images/pituku/pituku-square.jpeg"
              alt="pituku-square"
              style="width: 120px"
            />
          </div>
          <div class="w-4 flex">
            <div class="flex flex-column">
              <p class="text-5xl font-bold m-0 text-teal-400">INVOICE</p>
              <p>
                Tanggal Invoice :
                {{ FormatDate.toDDMMYYYY(invoice.invoiceDate) }}
              </p>
              <p>
                Batas Waktu Invoice :
                {{
                  invoice.invoiceDueDate == "COD"
                    ? "COD (Cash On Delivery)"
                    : FormatDate.toDDMMYYYY(invoice.invoiceDueDate)
                }}
              </p>
              <p>No. Invoice {{ invoice.invoiceNumber }}</p>
            </div>
          </div>
        </div>
        <hr />
        <div class="flex align-items-start mb-3 justify-content-between">
          <div class="w-6">
            <div class="flex flex-column">
              <p class="font-medium">PT Pituku Cordova International</p>
              <p>Ruko Terrace 8, Blok A no 98 Jl. Suvarna Sutera Boulevard</p>
              <p>Email: support@pituku.id</p>
              <p>+62895360988213</p>
            </div>
          </div>
          <div class="w-4 flex justify-content-end">
            <div class="flex flex-column">
              <p>Bill To:</p>
              <p class="font-medium">{{ inquiry.buyerName }}</p>
              <p>
                {{ inquiry.buyerStreet }} {{ inquiry.buyerCity }}
                {{ inquiry.buyerProvince }}
                {{ inquiry.buyerPostalCode }}
              </p>
              <p>{{ inquiry.buyerPhoneNumber }}</p>
            </div>
          </div>
        </div>
        <table class="mb-3">
          <tr>
            <th>Jenis Limbah</th>
            <th>Kuantitas</th>
            <th>Harga</th>
            <th>Sub Total</th>
          </tr>
          <tr v-for="(item, index) in inquiry.inquiryItems" v-bind:key="index">
            <td>{{ item.wasteSubCategoryName }}</td>
            <td>{{ item.quantity }}kg</td>
            <td>{{ FormatCurrency.toRupiah(item.fixedPrice) }}</td>
            <td>{{ FormatCurrency.toRupiah(item.quantity * item.fixedPrice) }}</td>
          </tr>
        </table>
        <hr />
        <div class="flex align-items-center mt-3 justify-content-between">
          <div class="w-6">
            <div class="field">
              <label for="note">Note :</label>
              <div class="p-inputgroup">
                <Textarea :autoResize="true" rows="3" cols="30" />
              </div>
            </div>
          </div>
          <div class="w-6">
            <div class="flex flex-column">
              <div class="flex justify-content-between">
                <p class="w-8 flex justify-content-end">Sub Total:</p>
                <p class="w-4 flex justify-content-end">
                  {{ FormatCurrency.toRupiah(invoice.subtotal) }}
                </p>
              </div>
              <div class="flex justify-content-between">
                <p class="w-8 flex justify-content-end">Pajak:</p>
                <p class="w-4 flex justify-content-end">
                  {{ FormatCurrency.toRupiah(invoice.tax) }}
                </p>
              </div>
              <div class="flex justify-content-between">
                <p class="w-8 flex justify-content-end">Diskon:</p>
                <p class="w-4 flex justify-content-end">
                  {{ FormatCurrency.toRupiah(invoice.discount) }}
                </p>
              </div>
              <div class="flex justify-content-between">
                <p class="w-8 flex justify-content-end">Biaya Pengiriman:</p>
                <p class="w-4 flex justify-content-end">
                  {{ FormatCurrency.toRupiah(invoice.shippingCost) }}
                </p>
              </div>
              <div class="flex justify-content-between">
                <p class="font-medium w-8 flex justify-content-end">Total:</p>
                <p class="font-medium w-4 flex justify-content-end">
                  {{ FormatCurrency.toRupiah(invoice.total) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>Pembayaran dapat di transfer ke rekening :</p>
        <p>BCA - 4977777614 an. PITUKU CORDOVA</p>
        <p>BRI - 050901002267309 an. PITUKU CORDOVA</p>
        <p>MANDIRI - 1200012583113 an. PITUKU CORDOVA</p>
      </div>
    </div>
    <!-- Akhir Invoice Download -->

    <!-- Shipment Download -->
    <div v-if="shipment" id="shipment-to-convert" class="card hidden">
      <div class="surface-section d-none">
        <div class="flex align-items-center mb-3 justify-content-between">
          <div class="w-6">
            <img
              src="images/pituku/pituku-square.jpeg"
              alt="pituku-square"
              style="width: 120px"
            />
          </div>
          <div class="w-4 flex">
            <div class="flex flex-column">
              <p class="text-5xl font-bold m-0 text-teal-400">SHIPMENT</p>
              <p class="font-medium">PT Pituku Cordova International</p>
              <p>Ruko Terrace 8, Blok A no 98 Jl. Suvarna Sutera Boulevard</p>
              <p>Email: support@pituku.id</p>
              <p>+62895360988213</p>
            </div>
          </div>
        </div>
        <div class="surface-section">
          <ul class="list-none p-0 m-0" v-if="shipment">
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">
                Tanggal Pengiriman
              </div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ FormatDate.toDDMMYYYY(shipment.sentDate) }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">Dikirim Dari</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ shipment.senderStreetName }} {{ shipment.senderCity }}
                {{ shipment.senderProvince }}
                {{ shipment.senderPostalCode }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">Kendaraan</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ shipment.vehicle }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">Plat Nomor</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ shipment.numberPlate }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">Supir</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ shipment.driver }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">Nomor HP</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ shipment.phoneNumber }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">
                Tanggal Estimasi Sampai
              </div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ FormatDate.toDDMMYYYY(shipment.receiveDateEstimation) }}
              </div>
            </li>
            <li
              class="
                flex
                align-items-center
                py-3
                px-2
                border-top-1
                surface-border
                flex-wrap
              "
            >
              <div class="text-500 w-6 md:w-2 font-medium">Alamat Tujuan</div>
              <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                {{ shipment.recipientStreet }} {{ shipment.recipientCity }}
                {{ shipment.recipientProvince }}
                {{ shipment.recipientPostalCode }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Akhir ShipmentDownload -->
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import InquiryService from "../../services/inquiry/InquiryService";
import { useRoute, useRouter } from "vue-router";

import FormatCurrency from "../../services/helpers/FormatCurrency";
import FormatDate from "../../services/helpers/FormatDate";

import html2pdf from "html2pdf.js";

export default {
  setup() {
    onMounted(async () => {
      getDetailInquiry(route.params.id);
    });

    const isLoading = ref(false);
    const isInvoiceDownload = ref(false);
    const isShipmentDownload = ref(false);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const inquiry = ref({});
    const invoice = ref({});
    const buyerPayment = ref({});
    const sellerPayment = ref({});
    const shipment = ref({});

    const templateMessage = ref("");

    const getDetailInquiry = async (id) => {
      try {
        isLoading.value = true;
        const response = await InquiryService.getDetail(id);
        if ((response.status = 201 && response.data.status == "success")) {
          inquiry.value = response.data.data.inquiry;
          invoice.value = response.data.data.invoice;
          buyerPayment.value = response.data.data.buyerPayment;
          sellerPayment.value = response.data.data.sellerPayment;
          shipment.value = response.data.data.shipment;

          if (shipment.value) {
            templateMessage.value = `https://wa.me/${
              shipment.value.phoneNumber
            }?text=Berikut%20adalah%20rincian%20Informasi%20Pengiriman%20untuk%20No%20Order%20%23%20%24${
              inquiry.value.id
            }%0ATanggal%20Pengiriman%3A%20${FormatDate.toDDMMYYYY(
              shipment.value.sentDate
            )}%0ADikirim%20Dari%3A%20${shipment.value.senderStreetName}%20${
              shipment.value.senderCity
            }%20${shipment.value.senderProvince}%20${
              shipment.value.senderPostalCode
            }%0AKendaraan%3A%20${shipment.value.vehicle}%0APlat%20Nomor%3A%20${
              shipment.value.numberPlate
            }%0ASupir%3A%20${shipment.value.driver}%0ANomor%20HP%3A%20%2B${
              shipment.value.phoneNumber
            }%0ATanggal%20Estimasi%20Sampai%3A%20${FormatDate.toDDMMYYYY(
              shipment.value.receiveDateEstimation
            )}%0AAlamat%20Tujuan%3A%20${shipment.value.recipientStreet}%20${
              shipment.value.recipientCity
            }%20${shipment.value.recipientProvince}%20${
              shipment.value.recipientPostalCode
            }`;
          }

          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const getTagColor = (status) => {
      switch (status) {
        case "Dipesan":
          return "danger";
        case "Belum Dikirim":
          return "danger";
        case "Menunggu Pembayaran":
          return "warning";
        case "Dalam Perjalanan":
          return "warning";
        case "Dibayar":
          return "success";
        case "Berhasil Dikirim":
          return "success";
        default:
          return "";
      }
    };

    const downloadInvoice = () => {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 0,
        filename: "Invoice - " + inquiry.value.id,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, imageTimeout: 15000 },
        jsPDF: { unit: "cm", format: "a4", orientation: "landscape" },
      });
    };

    const openPaymentLink = (link) => {
      window.open(link);
    }

    const downloadShipment = () => {
      isShipmentDownload.value = true;
      document.getElementById("shipment-to-convert").className = "block";
      html2pdf(document.getElementById("shipment-to-convert"), {
        margin: 0.5,
        filename: "Shipment - " + inquiry.value.id,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, imageTimeout: 15000 },
        jsPDF: { unit: "cm", format: "a4", orientation: "landscape" },
      }).then(() => {
        toast.add({
          severity: "success",
          summary: "Berhasil!",
          detail: "Berkas berhasil diunduh",
        });
        isShipmentDownload.value = false;
        document.getElementById("shipment-to-convert").className = "hidden";
      });
    };

    return {
      isLoading,
      isInvoiceDownload,
      isShipmentDownload,
      toast,
      inquiry,
      invoice,
      buyerPayment,
      sellerPayment,
      shipment,
      getTagColor,
      downloadInvoice,
      openPaymentLink,
      downloadShipment,
      FormatDate,
      FormatCurrency,
      templateMessage,
      goBack() {
        router.go(-1);
      },
      inquiryItems: [
        {
          wasteSubCategoryId: "a",
          wasteSubCategoryName: "Botol Pelastik",
          quantity: 100,
          priceMinimum: 1000,
          priceMaximum: 5000,
          fixedPrice: 3000,
        },
        {
          wasteSubCategoryId: "b",
          wasteSubCategoryName: "Besi Karat",
          quantity: 50,
          priceMinimum: 3000,
          priceMaximum: 8000,
          fixedPrice: 5000,
        },
        {
          wasteSubCategoryId: "c",
          wasteSubCategoryName: "Plastic PVC",
          quantity: 80,
          priceMinimum: 2000,
          priceMaximum: 4000,
          fixedPrice: 3000,
        },
      ],
    };
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
>
