import moment from 'moment';
class FormatDate {
  toDDMMYYYY(value) {
    return moment(value).format('DD-MM-YYYY');
  }
  toYYYYMMDD(value) {
    return moment(value).format('YYYY-MM-DD');
  }
  toDayOnly(value) {
    return moment(value).format('D');
  }
  toMonthYear() {
    return moment().locale('id').format('MMMM YYYY');
  }
}

export default new FormatDate();
