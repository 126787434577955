<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Total Pesanan</span>
            <div class="text-900 font-medium text-xl">{{ inquiryTotal }}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{ inquiryNew }}+ </span>
        <span class="text-500">dari minggu lalu </span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Total Pembeli</span>
            <div class="text-900 font-medium text-xl">{{ buyerTotal }}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-orange-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-money-bill text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{ buyerNew }}+ </span>
        <span class="text-500">dari minggu lalu </span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Total Mitra</span>
            <div class="text-900 font-medium text-xl">{{ sellerTotal }}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-cyan-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-inbox text-cyan-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{ sellerNew }}+ </span>
        <span class="text-500">dari minggu lalu </span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Kategori Sampah</span>
            <div class="text-900 font-medium text-xl">{{ wasteCategoryTotal }}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-purple-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-microsoft text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">{{ wasteCategoryNew }}+ </span>
        <span class="text-500">dari minggu lalu </span>
      </div>
    </div>

    <!-- Inquiry List -->
    <div class="col-12">
      <div class="card mb-0">
        <InquiryList />
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Sub Kategori Penjualan Sampah Terbaik</h5>
        </div>
        <ul class="list-none p-0 m-0" v-for="item in bestSellingWaste" :key="item.subCategory">
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.subCategory }}</span>
              <div class="mt-1 text-600">{{ item.category }}</div>
            </div>
            <div class="mt-2 md:mt-0 flex align-items-center">
              <span class="text-orange-500 ml-3 font-medium">{{ item.count }} Inquiry</span>
            </div>
          </li>
          <!-- <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Besi Tebal</span>
              <div class="mt-1 text-600">Logam</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-cyan-500 h-full" style="width: 16%"></div>
              </div>
              <span class="text-cyan-500 ml-3 font-medium">%16</span>
            </div>
          </li>
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Besi Tipis</span>
              <div class="mt-1 text-600">Logam</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-pink-500 h-full" style="width: 67%"></div>
              </div>
              <span class="text-pink-500 ml-3 font-medium">%67</span>
            </div>
          </li>
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">PET Bening</span>
              <div class="mt-1 text-600">Kelompok Plastik</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-green-500 h-full" style="width: 35%"></div>
              </div>
              <span class="text-green-500 ml-3 font-medium">%35</span>
            </div>
          </li>
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Botol Markisa/Bensin</span>
              <div class="mt-1 text-600">Kaca</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-purple-500 h-full" style="width: 75%"></div>
              </div>
              <span class="text-purple-500 ml-3 font-medium">%75</span>
            </div>
          </li>
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Kertas Putih</span>
              <div class="mt-1 text-600">Kertas</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-teal-500 h-full" style="width: 40%"></div>
              </div>
              <span class="text-teal-500 ml-3 font-medium">%40</span>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Papan peringkat</h5>
          <SelectButton
            v-model="leaderboardSelected"
            :options="leaderboardOptions"
            optionLabel="label"
            optionValue="value"
            @change="handleLeaderboardOptions"
            :unselectable="false"
          />
        </div>
        
        <div v-if="leaderboardSelected && leaderboardSelected == 'BUYER'">
          <ul class="list-none p-0 m-0 mt-4" v-for="item in leaderboardBuyerData" :key="item.companyName">
            <li
              class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
            >
              <div>
                <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.companyName }}</span>
                <!-- <div class="mt-1 text-600">{{ item.category }}</div> -->
              </div>
              <div class="mt-2 md:mt-0 flex align-items-center">
                <span class="text-orange-500 ml-3 font-medium">{{ item.totalInquiry }} Inquiry</span>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="leaderboardSelected && leaderboardSelected == 'SELLER'">
          <ul class="list-none p-0 m-0 mt-4" v-for="item in leaderboardSellerData" :key="item.companyName">
            <li
              class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
            >
              <div>
                <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.companyName }}</span>
                <!-- <div class="mt-1 text-600">{{ item.category }}</div> -->
              </div>
              <div class="mt-2 md:mt-0 flex align-items-center">
                <span class="text-orange-500 ml-3 font-medium">{{ FormatCurrency.toRupiah(item.totalIncome) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <ChartLine :inquiryDataset="datasetInquiry" />
      <ChartBar :unitEconomicDataset="datasetUnitEconomic" />

      <!-- <div class="card">
        <div class="flex align-items-center justify-content-between mb-4">
          <h5>Notifications</h5>
          <div>
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-plain p-button-rounded"
              @click="$refs.menu1.toggle($event)"
            ></Button>
            <Menu ref="menu1" :popup="true" :model="items"></Menu>
          </div>
        </div>

        <span class="block text-600 font-medium mb-3">TODAY</span>
        <ul class="p-0 mx-0 mt-0 mb-4 list-none">
          <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div
              class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
            >
              <i class="pi pi-dollar text-xl text-blue-500"></i>
            </div>
            <span class="text-900 line-height-3"
              >Richard Jones
              <span class="text-700"
                >has purchased a blue t-shirt for <span class="text-blue-500">79$</span></span
              >
            </span>
          </li>
          <li class="flex align-items-center py-2">
            <div
              class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
            >
              <i class="pi pi-download text-xl text-orange-500"></i>
            </div>
            <span class="text-700 line-height-3"
              >Your request for withdrawal of
              <span class="text-blue-500 font-medium">2500$</span> has been initiated.</span
            >
          </li>
        </ul>

        <span class="block text-600 font-medium mb-3">YESTERDAY</span>
        <ul class="p-0 m-0 list-none">
          <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div
              class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
            >
              <i class="pi pi-dollar text-xl text-blue-500"></i>
            </div>
            <span class="text-900 line-height-3"
              >Keyser Wick
              <span class="text-700"
                >has purchased a black jacket for <span class="text-blue-500">59$</span></span
              >
            </span>
          </li>
          <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div
              class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
            >
              <i class="pi pi-question text-xl text-pink-500"></i>
            </div>
            <span class="text-900 line-height-3"
              >Jane Davis
              <span class="text-700">has posted a new questions about your product.</span>
            </span>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
import DashboardService from '../services/dashboard/DashboardService';
import InquiryList from './inquiry/InquiryList';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from '@vue/runtime-core';
import ChartLine from './chart/ChartLine.vue';
import ChartBar from './chart/ChartBar.vue';
import LeaderboardService from '../services/dashboard/LeaderboardService';
import FormatCurrency from '../services/helpers/FormatCurrency';

export default {
  setup() {
    onMounted(async () => {
      await getDashboardData();
    });

    const isLoading = ref(false);
    const toast = useToast();

    const inquiryTotal = ref(null);
    const inquiryNew = ref(null);

    const buyerTotal = ref(null);
    const buyerNew = ref(null);

    const sellerTotal = ref(null);
    const sellerNew = ref(null);

    const wasteCategoryTotal = ref(null);
    const wasteCategoryNew = ref(null);

    const bestSellingWaste = ref([]);

    const datasetInquiry = ref([]);
    const datasetUnitEconomic = ref([]);

    const leaderboardBuyerData = ref([]);
    const leaderboardSellerData = ref([]);

    const leaderboardSelected = ref('BUYER');

    const leaderboardOptions = ref([
      { label: 'Buyer', value: 'BUYER' },
      { label: 'Seller', value: 'SELLER' },
    ]);

    const getDashboardData = async () => {
      try {
        isLoading.value = true;
        const response = await DashboardService.get();
        if ((response.status = 200 && response.data.status == 'success')) {
          const { summaryInfo, inquiryInfo, unitEconomicData } = response.data.data;

          inquiryTotal.value = summaryInfo.inquiry.total;
          inquiryNew.value = summaryInfo.inquiry.new;

          buyerTotal.value = summaryInfo.buyer.total;
          buyerNew.value = summaryInfo.buyer.new;

          sellerTotal.value = summaryInfo.seller.total;
          sellerNew.value = summaryInfo.seller.new;

          wasteCategoryTotal.value = summaryInfo.wasteCategory.total;
          wasteCategoryNew.value = summaryInfo.wasteCategory.new;

          bestSellingWaste.value = response.data.data.bestSellingWaste;

          datasetInquiry.value = inquiryInfo;
          datasetUnitEconomic.value = unitEconomicData;

          isLoading.value = false;
        }

        const leaderboardBuyer = await LeaderboardService.getBuyer();
        if((leaderboardBuyer.status = 200 && leaderboardBuyer.data.status == 'success')){
          leaderboardBuyerData.value = leaderboardBuyer.data.data;
        }

        const leaderboardSeller = await LeaderboardService.getSeller();
        if((leaderboardSeller.status = 200 && leaderboardSeller.data.status == 'success')){
          leaderboardSellerData.value = leaderboardSeller.data.data;
        }

      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const handleLeaderboardOptions = (event) => {
      leaderboardSelected.value = event.value;
      console.log(event.value);
    };

    return {
      inquiryTotal,
      inquiryNew,
      buyerTotal,
      buyerNew,
      sellerTotal,
      sellerNew,
      wasteCategoryTotal,
      wasteCategoryNew,
      bestSellingWaste,
      datasetInquiry,
      datasetUnitEconomic,
      leaderboardBuyerData,
      leaderboardSellerData,
      leaderboardOptions,
      leaderboardSelected,
      handleLeaderboardOptions,
      FormatCurrency
    };
  },
  components: {
    InquiryList,
    ChartLine,
    ChartBar,
  },
};
</script>
