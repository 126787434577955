import API from '../API';

class SellerService {
  getList() {
    return API.get('/api/sellers');
  }
  getListWithFilter(priceMinimum, priceMaximum, wasteSubCategory, quantity) {
    return API.get(
      `/sellers?priceMinimum=${priceMinimum}&priceMaximum=${priceMaximum}&wasteSubCategory=${wasteSubCategory}&quantity=${quantity}`,
    );
  }
  getSellerByInquiry(inquiryId) {
    return API.get(`/api/sellers?inquiry=${inquiryId}`);
  }
  getDetail(id) {
    return API.get(`/api/sellers/${id}`);
  }
  approve(id) {
    return API.put(`/api/sellers/${id}/approve`);
  }
  decline(id, data) {
    return API.put(`/api/sellers/${id}/decline`, data);
  }
}

export default new SellerService();
