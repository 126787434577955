<template>
  <Toast />
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button icon="pi pi-arrow-left" class="p-button-info p-button-sm mr-2" @click="goBack()" />
        <div class="font-medium text-3xl text-900">Tambah Informasi Pengiriman</div>
      </div>
    </div>
    <form>
      <div class="grid p-fluid align-items-center">
        <div class="col-12">
          <div class="field">
            <label for="sentDate">Tanggal Pengiriman</label>
            <div class="p-inputgroup">
              <Calendar inputId="dateformat" v-model="v$.sentDate.$model" dateFormat="dd-mm-yy" />
            </div>
            <small
              v-if="(v$.sentDate.$invalid && submitted) || v$.sentDate.$pending.$response"
              class="p-error"
            >
              {{ v$.sentDate.required.$message.replace('Value', 'Send Date') }}
            </small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vehicle">Kendaraan</label>
            <InputText type="text" v-model="v$.vehicle.$model" />
            <small
              v-if="(v$.vehicle.$invalid && submitted) || v$.vehicle.$pending.$response"
              class="p-error"
            >
              {{ v$.vehicle.required.$message.replace('Value', 'Vehicle') }}
            </small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="numberPlate">Plat Nomor</label>
            <InputText type="text" v-model="v$.numberPlate.$model" />
            <small
              v-if="(v$.numberPlate.$invalid && submitted) || v$.numberPlate.$pending.$response"
              class="p-error"
            >
              {{ v$.numberPlate.required.$message.replace('Value', 'Number Plate') }}
            </small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vehicle">Supir</label>
            <InputText type="text" v-model="v$.driver.$model" />
            <small
              v-if="(v$.driver.$invalid && submitted) || v$.driver.$pending.$response"
              class="p-error"
            >
              {{ v$.driver.required.$message.replace('Value', 'driver') }}
            </small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="phoneNumber">Nomor HP</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">+62</span>
              <InputText type="number" v-model="v$.phoneNumber.$model" />
            </div>

            <small
              v-if="(v$.phoneNumber.$invalid && submitted) || v$.phoneNumber.$pending.$response"
              class="p-error"
            >
              {{ v$.phoneNumber.required.$message.replace('Value', 'Nomor HP') }}
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="receiveDateEstimation">Tanggal Estimasi Tiba</label>
            <div class="p-inputgroup">
              <Calendar
                inputId="dateformat"
                v-model="v$.receiveDateEstimation.$model"
                dateFormat="dd-mm-yy"
              />
            </div>
            <small
              v-if="
                (v$.receiveDateEstimation.$invalid && submitted) ||
                v$.receiveDateEstimation.$pending.$response
              "
              class="p-error"
            >
              {{
                v$.receiveDateEstimation.required.$message.replace(
                  'Value',
                  'Receive Date Estimation',
                )
              }}
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="receiveDateEstimation">Upload Dokumentasi</label>
            <div class="mt-2">
              <a-upload-dragger
                id="file-upload"
                accept="image/png, image/jpg, image/jpeg"
                class="rounded"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @remove="handleRemove"
                list-type="picture"
                :max-count="3"
                :multiple="true"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  Pilih Gambar (maksimal 3)
                </a-button>
              </a-upload-dragger>
            </div>
            <!-- <small
              v-if="(v$.documentation.$invalid && submitted) || v$.documentation.$pending.$response"
              class="p-error"
            >
              {{
                v$.receiveDateEstimation.required.$message.replace(
                  'Value',
                  'Documentation',
                )
              }}
            </small> -->
          </div>
        </div>
      </div>
      <div class="grid p-fluid justify-content-end">
        <div class="col-12 xl:col-2">
          <div class="field">
            <label for="button"></label>
            <Button
              @click="handleUpload(!v$.$invalid)"
              label="Buat Informasi Pengiriman"
              class="p-button-success"
              :loading="isLoading"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive, ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import { UploadOutlined } from '@ant-design/icons-vue';

import ShipmentService from '../../services/shipment/ShipmentService';
import authHeader from '../../services/authentication/AuthHeader';
import FormatDate from '../../services/helpers/FormatDate';

export default {
  components: {
    UploadOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const state = reactive({
      sentDate: null,
      vehicle: '',
      numberPlate: '',
      driver: '',
      phoneNumber: '',
      receiveDateEstimation: null,
    });

    const rules = {
      sentDate: { required },
      vehicle: { required },
      numberPlate: { required },
      driver: { required },
      phoneNumber: { required },
      receiveDateEstimation: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);

    /* Upload Handler */
    const fileList = ref([]);

    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const beforeUpload = (file) => {
      fileList.value = [...fileList.value, file];
      return false;
    };

    const handleUpload = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      const formData = new FormData();

      fileList.value.forEach((file) => {
        formData.append('shipment', file);
      });
      try {
        isLoading.value = true;
        const response = await axios.post(
          process.env.VUE_APP_API_HOST + '/api/shipments/upload',
          formData,
          {
            headers: {
              Authorization: authHeader(),
            },
          },
        );

        if (response.data.status === 'success') {
          await createShipment(response.data.data.documentation);
          isLoading.value = false;
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };
    /* Akhir Upload Handler */

    const createShipment = async (documentation) => {
      try {
        isLoading.value = true;
        const response = await ShipmentService.create(
          JSON.stringify({
            inquiryId: route.params.id,
            sentDate: FormatDate.toYYYYMMDD(state.sentDate),
            vehicle: state.vehicle,
            numberPlate: state.numberPlate,
            driver: state.driver,
            phoneNumber: '+62' + state.phoneNumber,
            receiveDateEstimation: FormatDate.toYYYYMMDD(state.receiveDateEstimation),
            documentation: documentation,
          }),
        );
        if ((response.status = 200 && response.data.status == 'success')) {
          await router.push({ name: 'detailInquiry' });
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      v$,
      submitted,
      fileList,
      beforeUpload,
      handleRemove,
      handleUpload,
      createShipment,
      goBack() {
        router.go(-1);
      },
    };
  },
};
</script>

<style></style>
