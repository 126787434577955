import API from "../API";

class LeaderboardService {
  getSeller() {
    return API.get("/api/leaderboard/sellers");
  }
  getBuyer() {
    return API.get("/api/leaderboard/buyers");
  }
}

export default new LeaderboardService();
