import axios from 'axios';
import authHeader from './authentication/AuthHeader';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    'Content-Type': 'application/json',
    Authorization: authHeader(),
  },
});
export default instance;
