<template>
  <DataTable
    :scrollable="true"
    :value="SellerList"
    :paginator="true"
    class="p-datatable-sellers"
    :rows="10"
    dataKey="id"
    :rowHover="true"
    :loading="isLoading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    responsiveLayout="scroll"
    removableSort
    v-model:filters="filters"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <h5 class="m-0">Daftar Seller</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
        </span>
      </div>
    </template>
    <template #empty> No sellers found. </template>
    <Column header="Nama" style="min-width: 14rem">
      <template #body="{ data }"> {{ data.firstName }} {{ data.lastName }} </template>
    </Column>
    <Column field="email" header="Email" sortable style="min-width: 14rem"> </Column>
    <Column field="companyName" header="Nama Perusahaan" sortable style="min-width: 14rem"></Column>
    <Column field="city" header="Kota Perusahaan" sortable style="min-width: 14rem"></Column>
    <Column field="province" header="Provinsi" sortable style="min-width: 14rem"></Column>
    <Column field="averageSales" header="Rata-rata Penjualan" sortable style="min-width: 14rem">
      <template #body="{ data }"> {{ data.averageSales }} ton </template>
    </Column>
    <Column
      field="status"
      header="Status"
      sortable
      style="min-width: 14rem"
      frozen
      alignFrozen="right"
    >
      <template #body="{ data }">
        <Tag :value="data.status" :severity="getTagColor(data.status)"></Tag>
      </template>
    </Column>
    <Column header="Opsi" style="min-width: 14rem" frozen alignFrozen="right">
      <template #body="{ data }">
        <Button type="button" icon="pi pi-ellipsis-h" @click="toggle(data.id, $event)"></Button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </template>
    </Column>
    <Dialog
      :header="title"
      v-model:visible="displayConfirmation"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>{{ message }}</span>
      </div>

      <template #footer>
        <Button
          :loading="isLoading"
          label="Ya"
          icon="pi pi-check"
          @click="assignWasteBank()"
          class="p-button"
          autofocus
        />
        <Button
          label="Tidak"
          icon="pi pi-times"
          @click="closeConfirmation()"
          class="p-button-text"
        />
      </template>
    </Dialog>
    <Toast />
  </DataTable>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from '@vue/runtime-core';
import { useToast } from 'primevue/usetoast';
import SellerService from '../../services/seller/SellerService';
import { FilterMatchMode } from 'primevue/api';
import { useRoute, useRouter } from 'vue-router';
import InquiryService from '../../services/inquiry/InquiryService';

export default {
  setup() {
    onMounted(async () => {
      sessionStorage.setItem('inquiryId', route.params.id);
      getAllSeller();
    });

    const isLoading = ref(false);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const sellerId = ref(null);
    const wasteBankId = ref(null);

    const SellerList = ref([]);
    const menu = ref();
    const items = ref([
      {
        label: 'Action',
        items: [
          {
            label: 'Assign Mitra Pituku',
            icon: 'pi pi-pencil',
            command: () => {
              openConfirmation(
                'Assign Mitra Pituku',
                'Apakah anda yakin untuk assign waste bank ini?',
              );
            },
          },
          {
            label: 'Detail',
            icon: 'pi pi-eye',
            to: { name: 'detailSeller', params: { id: sellerId } },
          },
        ],
      },
    ]);

    const toggle = (id, event) => {
      menu.value.toggle(event);
      sellerId.value = id;
    };

    /* Dialog Confirmation */
    const displayConfirmation = ref(false);

    const modal = reactive({
      title: '',
      message: '',
    });

    const openConfirmation = (title, message) => {
      displayConfirmation.value = true;
      modal.title = title;
      modal.message = message;
    };

    const closeConfirmation = () => {
      displayConfirmation.value = false;
    };
    /* Akhir Dialog Confirmation */

    const getAllSeller = async () => {
      try {
        isLoading.value = true;
        const response = await SellerService.getSellerByInquiry(route.params.id);
        // const response = await SellerService.getListWithFilter(
        //   priceMinimum,
        //   priceMaximum,
        //   wasteSubCategory,
        //   quantity,
        // );
        if ((response.status = 201 && response.data.status == 'success')) {
          SellerList.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const assignWasteBank = async () => {
      try {
        isLoading.value = true;
        const response = await InquiryService.assignWasteBank(
          JSON.stringify({
            inquiryId: sessionStorage.getItem('inquiryId'),
            wasteBankId: sellerId.value,
          }),
        );
        if ((response.status = 201 && response.data.status == 'success')) {
          isLoading.value = false;
          closeConfirmation();
          await router.push({ name: 'detailInquiry' });
          toast.add({
            severity: 'success',
            summary: 'Success',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
            life: 3000,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
            life: 3000,
          });
        }
        isLoading.value = false;
        closeConfirmation();
      }
    };

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const getTagColor = (status) => {
      switch (status) {
        case 'In Review':
          return 'warning';
        case 'Approved':
          return 'success';
        case 'Declined':
          return 'danger';
        default:
          return '';
      }
    };

    return {
      isLoading,
      toast,
      SellerList,
      filters,
      items,
      menu,
      toggle,
      getTagColor,
      displayConfirmation,
      openConfirmation,
      closeConfirmation,
      assignWasteBank,
      wasteBankId,
      ...toRefs(modal),
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-sellers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog {
  min-width: 6rem;
}
</style>
