<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button icon="pi pi-arrow-left" class="p-button-info p-button-sm mr-2" @click="goBack()" />
        <div class="font-medium text-3xl text-900">Tambah Recycler</div>
        <Button icon="pi pi-save" label="Simpan" class="p-button-success p-button-sm ml-auto"
          @click="createRecycler(!v$.$invalid)" :loading="isLoading" />
      </div>
    </div>
    <form class="mt-5">
      <div class="grid p-fluid">
        <div class="col-12 mb-2">
          <div>
            <div id="map" />
          </div>
        </div>
        <div class="col-6">
          <div class="field">
            <label for="title">Nama Perusahaan</label>
            <div class="p-inputgroup">
              <InputText type="text" v-model="v$.companyName.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">No HP</label>
            <div class="p-inputgroup">
              <InputText type="number" v-model="v$.phoneNumber.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">Jenis Limbah</label>
            <div class="p-inputgroup">
              <Dropdown v-model="v$.selectedWasteType.$model" :options="wasteTypes" optionLabel="wasteTypeName"
                optionValue="id" placeholder="Pilih jenis sampah" class="w-full md:w-14rem"
                @change="getWasteSubTypes()" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="field">
            <label for="title">Email</label>
            <div class="p-inputgroup">
              <InputText type="text" v-model="v$.email.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">Alamat</label>
            <div class="p-inputgroup">
              <AutoComplete v-model="v$.address.$model" optionLabel="value" :suggestions="suggestionLocations"
                @complete="onCompleteLocation" @item-select="onCompleteDLocationClick" :delay="delay" />
            </div>
          </div>
          <div class="field">
            <label for="title">Produk</label>
            <div v-for="wasteSubType of wasteSubTypes" :key="wasteSubType.id" class="flex align-items-center">
              <Checkbox v-model="v$.wasteInventories.$model" :inputId="wasteSubType.id" name="wasteSubType"
                class="my-1 mr-2" :value="wasteSubType.id" />
              <label :for="wasteSubType.id">{{ wasteSubType.wasteSubTypeName }}</label>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import FormatCurrency from "../../services/helpers/FormatCurrency";
import { onMounted } from "@vue/runtime-core";
import DeliveryService from "../../services/delivery/DeliveryService";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  setup() {
    let map = null;
    onMounted(async () => {
      getWasteTypes()
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZmFjaHJ5cGl0dWt1IiwiYSI6ImNsaXM4YWltdDI5NXIzZWxnMzk1Z2Z1N24ifQ.XFdZGywcziEcmfRgJxD_jQ";
      map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v9",
        zoom: 12,
        center: [106.856694, -6.425616],
      });
      map.on("load", () => {
        // TODO: Here we want to load a layer
        // TODO: Here we want to load/setup the popup
      });
    });
    const delay = 2000;
    const router = useRouter();
    const toast = useToast();

    const state = reactive({
      companyName: null,
      email: null,
      phoneNumber: null,
      address: null,
      latitude: null,
      longitude: null,
      marker: null,
      selectedWasteType: null,
      wasteInventories: [],
    });

    const rules = {
      companyName: { required },
      email: { required },
      phoneNumber: { required },
      address: { required },
      latitude: { required },
      longitude: { required },
      selectedWasteType: { required },
      wasteInventories: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);
    const zoom = ref(2);

    const suggestionLocations = ref([]);
    const wasteTypes = ref([]);
    const wasteSubTypes = ref([]);

    const createRecycler = async () => {
      submitted.value = true;
      isLoading.value = true;

      DeliveryService.addRecyclers({
        companyName: state.companyName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        address: state.address.value,
        latitude: state.latitude,
        longitude: state.longitude,
        wasteInventories: state.wasteInventories
      }).then(async res => {
        console.log(res.data);
        if ((res.status = 200 && res.data.status == "success")) {
          toast.add({
            severity: "success",
            summary: "Berhasil!",
            detail: res.data.message,
            life: 3000,
          });
          await router.push({ name: "listRecycler" });
        }
      }).catch(err => {
        if (err.response) {
          toast.add({
            severity: "error",
            summary: err.response.data.status,
            detail: err.response.data.message,
          });
        } else if (err.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: err.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
          });
        }
      }).finally(() => {
        isLoading.value = false;
      })
    };

    const getWasteTypes = async () => {
      const res = await DeliveryService.getWasteTypes();
      wasteTypes.value = res.data.data;
    };

    const onCompleteLocation = async (event) => {
      const res = await DeliveryService.queryAutoCompletePlaces(event.query);
      suggestionLocations.value = res.data.predictions.map(
        (location) => ({
          value: location.description,
          id: location.place_id,
        })
      );
    }

    const onCompleteDLocationClick = async (event) => {
      const res = await DeliveryService.getPlace(event.value.id);
      state.latitude = res.data.result.geometry.location.lat;
      state.longitude = res.data.result.geometry.location.lng;
      state.marker = new mapboxgl.Marker()
        .setLngLat([
          res.data.result.geometry.location.lng,
          res.data.result.geometry.location.lat,
        ])
        .addTo(map);

      map.flyTo({
        center: [
          res.data.result.geometry.location.lng,
          res.data.result.geometry.location.lat
        ]
      })
    }

    const getWasteSubTypes = async () => {
      const res = await DeliveryService.getWasteSubTypes(
        state.selectedWasteType
      );
      wasteSubTypes.value = res.data.data
    };

    return {
      FormatCurrency,
      isLoading,
      v$,
      submitted,
      goBack() {
        router.go(-1);
      },
      createRecycler,
      zoom,
      delay,
      suggestionLocations,
      onCompleteLocation,
      onCompleteDLocationClick,
      wasteSubTypes,
      getWasteSubTypes,
      wasteTypes
    };
  },
};
</script>

<style>
#map {
  height: 400px;
  border-radius: 12px;
}
</style>
