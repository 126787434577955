import API from "../API";

class WasteService {
  getList() {
    return API.get("/api/waste-categories");
  }
  detailCategory(id) {
    return API.get(`/api/waste-categories/${id}`);
  }
  getSubList() {
    return API.get("/api/waste-subcategories");
  }
  createCategory(data) {
    return API.post("/api/waste-categories", data);
  }
  updateCategory(data) {
    return API.put(`/api/waste-categories/${data.id}`, data);
  }
  createSubCategory(data) {
    return API.post("/api/waste-subcategories", data);
  }
}

export default new WasteService();
