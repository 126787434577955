<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button icon="pi pi-arrow-left" class="p-button-info p-button-sm mr-2" @click="goBack()" />
        <div class="font-medium text-3xl text-900">Tambah Kategori Sampah</div>
      </div>
    </div>
    <form @submit.prevent="createWasteCategory(!v$.$invalid)">
      <div class="grid p-fluid align-items-center">
        <div class="col-12">
          <div class="field">
            <label for="categoryName">Nama Kategori Sampah</label>
            <InputText type="text" v-model="v$.categoryName.$model" />
            <small
              v-if="(v$.categoryName.$invalid && submitted) || v$.categoryName.$pending.$response"
              class="p-error"
            >
              {{ v$.categoryName.required.$message.replace('Value', 'Waste Category') }}
            </small>
          </div>
        </div>
      </div>
      <div class="grid p-fluid justify-content-end">
        <div class="col-12 xl:col-2">
          <div class="field">
            <label for="button"></label>
            <Button
              type="submit"
              label="Buat Kategori Sampah"
              class="p-button-success"
              :loading="isLoading"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive, ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import WasteService from '../../services/waste-info/WasteService';

export default {
  setup() {
    const router = useRouter();
    const toast = useToast();

    const state = reactive({
      categoryName: null,
    });

    const rules = {
      categoryName: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);

    const createWasteCategory = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      try {
        isLoading.value = true;
        const response = await WasteService.createCategory(
          JSON.stringify({
            categoryName: state.categoryName,
          }),
        );
        if ((response.status = 201 && response.data.status == 'success')) {
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
          });
          router.go(-1);
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      v$,
      submitted,
      createWasteCategory,
      goBack() {
        router.go(-1);
      },
    };
  },
};
</script>

<style></style>
