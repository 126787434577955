import API from '../API';

class WithdrawalService {
  getList(page) {
    return API.get(`/api/wallets/withdrawals?page=${page}`);
  }
  getDetail(id) {
    return API.get(`/api/wallets/withdrawals/${id}`);
  }
}

export default new WithdrawalService();
