<template>
  <div class="card">
    <h4>Filter</h4>
    <div class="grid">
      <div class="col-12 lg:col-6">
        <h5>Assign Waste Bank</h5>
        <SelectButton
          v-model="filterAssign"
          :options="assigned"
          optionLabel="label"
          optionValue="value"
          @change="handlefilterAssign"
          :unselectable="false"
        />
      </div>
      <div class="col-12 lg:col-6">
        <h5>Pembayaran</h5>
        <SelectButton
          v-model="filterPayment"
          :options="paymentStatus"
          optionLabel="label"
          optionValue="value"
          @change="handlefilterPayment"
          :unselectable="false"
        />
      </div>
      <div class="col-12 lg:col-6">
        <h5>Pengiriman</h5>
        <SelectButton
          v-model="filterShipment"
          :options="shipmentStatus"
          optionLabel="label"
          optionValue="value"
          @change="handlefilterShipment"
          :unselectable="false"
        />
      </div>
      <div class="col-12 lg:col-6">
        <h5>Pencarian</h5>
        <span class="p-input-icon-left" style="width: 100%">
          <i class="pi pi-search" />
          <InputText
            class="w-full"
            v-model="query"
            placeholder="Keyword Search"
            @keydown.enter="handleSearch"
          />
        </span>
      </div>
    </div>
  </div>
  <div class="card">
    <h4>Daftar Pesanan</h4>
    <DataTable
      :lazy="true"
      :scrollable="true"
      :value="InquiryList"
      @page="onPage($event)"
      :rows="10"
      :paginator="true"
      responsiveLayout="scroll"
      removableSort
      :loading="isLoading"
      :totalRecords="totalItems"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Menampilkan {first} - {last} dari {totalRecords} baris"
      :rowsPerPageOptions="[10, 25, 50]"
      :rowHover="true"
      dataKey="id"
      class="p-datatable-sellers"
    >
      <template #empty> Pesanan tidak ditemukan. </template>
      <Column style="min-width: 14rem" header="Jenis Limbah">
        <template #body="{ data }">
          {{ data.wasteSubCategoryName.join(", ") }}
        </template>
      </Column>
      <Column
        style="min-width: 14rem"
        field="companyName"
        header="Nama Perusahaan"
      ></Column>
      <Column
        style="min-width: 14rem"
        field="wasteBank"
        header="Mitra Pituku"
      ></Column>
      <Column
        style="min-width: 14rem"
        field="wasteBankCity"
        header="Kota Mitra Pituku"
      ></Column>
      <Column
        style="min-width: 14rem"
        field="city"
        header="Kota Perusahaan"
      ></Column>
      <Column style="min-width: 14rem" header="Kuantitas">
        <template #body="{ data }"> {{ data.totalQuantity }}kg </template>
      </Column>
      <Column style="min-width: 14rem" header="Tanggal Pemesanan">
        <template #body="{ data }">
          {{ FormatDate.toDDMMYYYY(data.orderDate) }}
        </template>
      </Column>
      <Column style="min-width: 14rem" header="Tanggal Dibuat">
        <template #body="{ data }">
          {{ FormatDate.toDDMMYYYY(data.createdAt) }}
        </template>
      </Column>
      <Column style="min-width: 14rem" header="Status Pembayaran">
        <template #body="{ data }">
          <Tag
            :value="data.paymentStatus"
            :severity="getTagColor(data.paymentStatus)"
          ></Tag>
        </template>
      </Column>
      <Column style="min-width: 14rem" header="Status Pengiriman">
        <template #body="{ data }">
          <Tag
            :value="data.shipmentStatus"
            :severity="getTagColor(data.shipmentStatus)"
          ></Tag>
        </template>
      </Column>
      <Column header="Opsi" style="min-width: 8rem" frozen alignFrozen="right">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-ellipsis-h"
            @click="
              toggle(
                data.id,
                data.priceMinimum,
                data.priceMaximum,
                data.wasteSubCategoryName,
                data.quantity,
                $event
              )
            "
          ></Button>
          <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
        </template>
      </Column>
    </DataTable>
  </div>

  <Toast />
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import InquiryService from "../../services/inquiry/InquiryService";

import FormatCurrency from "../../services/helpers/FormatCurrency";
import FormatDate from "../../services/helpers/FormatDate";

export default {
  setup() {
    onMounted(async () => {
      getAllInquiry();
    });

    const isLoading = ref(false);
    const toast = useToast();

    const inquiryId = ref(null);

    const menu = ref();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(null);
    const totalItems = ref(null);
    const query = ref("");

    const filterPayment = ref("");
    const filterShipment = ref("");
    const filterAssign = ref("");

    const items = ref([
      {
        label: "Action",
        items: [
          {
            label: "Cari Mitra Pituku",
            icon: "pi pi-search",
            to: {
              name: "assignInquiry",
              params: {
                id: inquiryId,
              },
            },
          },
          {
            label: "Detail",
            icon: "pi pi-eye",
            to: { name: "detailInquiry", params: { id: inquiryId } },
          },
        ],
      },
    ]);

    const paymentStatus = ref([
      { label: "Semua", value: "" },
      { label: "Dipesan", value: "&paymentStatus=Dipesan" },
      {
        label: "Menunggu Pembayaran",
        value: "&paymentStatus=Menunggu Pembayaran",
      },
      { label: "Dibayar", value: "&paymentStatus=Dibayar" },
    ]);
    const shipmentStatus = ref([
      { label: "Semua", value: "" },
      { label: "Belum Dikirim", value: "&shipmentStatus=Belum Dikirim" },
      { label: "Dalam Perjalanan", value: "&shipmentStatus=Dalam Perjalanan" },
      { label: "Berhasil Dikirim", value: "&shipmentStatus=Berhasil Dikirim" },
    ]);
    const assigned = ref([
      { label: "Semua", value: "" },
      { label: "Sudah", value: "&assigned=true" },
      { label: "Belum", value: "&assigned=false" },
    ]);

    const toggle = (
      id,
      priceMinimum,
      priceMaximum,
      wasteSubCategory,
      quantity,
      event
    ) => {
      menu.value.toggle(event);
      inquiryId.value = id;
      sessionStorage.setItem("priceMinimum", priceMinimum);
      sessionStorage.setItem("priceMaximum", priceMaximum);
      sessionStorage.setItem("wasteSubCategory", wasteSubCategory);
      sessionStorage.setItem("quantity", quantity);
    };

    const InquiryList = ref([]);

    const getAllInquiry = async () => {
      try {
        isLoading.value = true;
        const response = await InquiryService.getList(
          currentPage.value,
          filterPayment.value,
          filterShipment.value,
          filterAssign.value,
          pageSize.value,
          query.value
        );
        if ((response.status = 201 && response.data.status == "success")) {
          InquiryList.value = response.data.data;
          currentPage.value = response.data.currentPage;
          totalPages.value = response.data.totalPages;
          totalItems.value = response.data.totalItems;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const handleSearch = (event) => {
      if (event.keyCode === 13) {
        getAllInquiry()
      }
    };

    const onPage = (event) => {
      currentPage.value = event.page + 1;
      pageSize.value = event.rows;
      getAllInquiry();
    };

    const handlefilterAssign = (event) => {
      filterAssign.value = event.value;
      getAllInquiry();
    };

    const handlefilterPayment = (event) => {
      filterPayment.value = event.value;
      getAllInquiry();
    };

    const handlefilterShipment = (event) => {
      filterShipment.value = event.value;
      getAllInquiry();
    };

    const getTagColor = (status) => {
      switch (status) {
        case "Dipesan":
          return "danger";
        case "Belum Dikirim":
          return "danger";
        case "Menunggu Pembayaran":
          return "warning";
        case "Dalam Perjalanan":
          return "warning";
        case "Dibayar":
          return "success";
        case "Berhasil Dikirim":
          return "success";
        default:
          return "";
      }
    };

    return {
      isLoading,
      toast,
      InquiryList,
      FormatCurrency,
      FormatDate,
      items,
      menu,
      toggle,
      getTagColor,
      onPage,
      totalItems,
      filterPayment,
      filterShipment,
      filterAssign,
      paymentStatus,
      shipmentStatus,
      assigned,
      handlefilterAssign,
      handlefilterPayment,
      handlefilterShipment,
      query,
      handleSearch
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-sellers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog {
  min-width: 6rem;
}
</style>
