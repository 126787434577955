import API from '../API';

class PaymentService {
  createInvoice(data) {
    return API.post('/api/invoices', data);
  }
  editInvoice(data) {
    return API.put('/api/invoices', data);
  }
  confirmBuyer(data) {
    return API.post('/api/payments/buyer', data);
  }
  confirmSeller(data) {
    return API.post('/api/payments/seller', data);
  }
}

export default new PaymentService();
