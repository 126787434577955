<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-info p-button-sm mr-2"
          @click="goBack()"
        />
        <div class="font-medium text-3xl text-900">Detail Driver</div>
        <Button
          icon="pi pi-save"
          label="Simpan"
          class="p-button-success p-button-sm ml-auto"
          @click="createArticle(!v$.$invalid)"
        />
      </div>
    </div>
    <form class="mt-5">
      <div class="grid p-fluid">
        <div class="col-2 mb-2">
          <div class="p-5">
            <img
              src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=2000"
              style="width: 100%; border-radius: 100%"
              alt="Image"
            />
          </div>
          <small
            v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response"
            class="p-error"
            >{{
              v$.body.required.$message.replace("Value", "Konten Artikel")
            }}</small
          >
        </div>
        <div class="col-4">
          <div class="field">
            <label for="title">Nama Lengkap</label>
            <div class="p-inputgroup">
              <InputText />
            </div>
          </div>
          <div class="field">
            <label for="title">No HP</label>
            <div class="p-inputgroup">
              <InputText />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="field">
            <label for="title">Email</label>
            <div class="p-inputgroup">
              <InputText />
            </div>
          </div>
          <div class="field">
            <label for="title">Kendaraan</label>
            <Button label="Colt Diesel" />
          </div>
        </div>
      </div>
    </form>

    <div class="font-medium text-3xl text-900 mt-5">History Delivery</div>
    <DataTable
        :value="deliveryList"
        :paginator="true"
        class="p-datatable-wastes mt-3"
        :rows="5"
        dataKey="id"
        :rowHover="true"
        :loading="isLoading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Menampilkan {first} - {last} dari {totalRecords} baris"
        responsiveLayout="scroll"
        removableSort
        v-model:filters="filters"
      >
        <template #empty> No delivery found. </template>
        <Column field="id" header="ID" sortable style="max-width: 2rem" />
        <Column field="routes" header="Rute" sortable style="max-width: 6rem" />
        <Column
          field="driver"
          header="Sopir"
          sortable
          style="max-width: 6rem"
        />
        <Column
          field="scheduleAt"
          header="Jadwal Pengiriman"
          sortable
          style="max-width: 6rem"
        />
        <Column
          field="createdAt"
          header="Created At"
          sortable
          style="max-width: 6rem"
        />
        <Column
          header="Opsi"
          style="max-width: 2rem"
          frozen
          alignFrozen="right"
        >
          <template #body="{ data }">
            <Button
              type="button"
              icon="pi pi-ellipsis-h"
              @click="toggle(data.id, $event)"
            ></Button>
            <Menu ref="menu" :model="items" :popup="true" />
          </template>
        </Column>
        <Toast />
      </DataTable>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import FormatCurrency from "../../services/helpers/FormatCurrency";
import { onMounted } from "@vue/runtime-core";
import ArticleService from "../../services/article/ArticleService";
import ImageUploader from "quill-image-uploader";
import htmlEditButton from "quill-html-edit-button";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  setup() {
    onMounted(async () => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZmFjaHJ5cGl0dWt1IiwiYSI6ImNsaXM4YWltdDI5NXIzZWxnMzk1Z2Z1N24ifQ.XFdZGywcziEcmfRgJxD_jQ";
      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v9",
        zoom: 12,
        center: [106.856694, -6.425616],
      });
      map.on("load", () => {
        // TODO: Here we want to load a layer
        // TODO: Here we want to load/setup the popup
      });

      new mapboxgl.Marker().setLngLat([106.856694, -6.425616]).addTo(map);
    });
    const router = useRouter();
    const toast = useToast();

    const quillOptions = {
      // debug: "info",
      modules: {},
      placeholder: "Compose an epic...",
      theme: "snow",
    };

    const state = reactive({
      wasteSubCategoryItems: [],
      shippingCost: null,
      tax: null,
      invoiceDate: null,
      discount: null,
      title: null,
      description: null,
      coverPreview: null,
      cover: null,
      body: "",
    });

    const rules = {
      title: { required },
      description: { required },
      cover: { required },
      coverPreview: { required },
      body: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);
    const deliveryList = ref([
      {
        id: "1",
        routes: "Depok - Bogor",
        driver: "Agus nadi",
        scheduleAt: "22 Jun 2023 18:00",
        createdAt: "6 Jun 2023 03:00",
      },
      {
        id: "2",
        routes: "Depok - Bogor",
        driver: "Siamase",
        scheduleAt: "22 Jun 2023 18:00",
        createdAt: "6 Jun 2023 03:00",
      },
      {
        id: "3",
        routes: "Depok - Bogor",
        driver: "Sihotang",
        scheduleAt: "22 Jun 2023 18:00",
        createdAt: "6 Jun 2023 03:00",
      },
    ]);
    const recyclers = ref([
      {
        company: "PT. Limbah Besar",
        phoneNumber: "08124234",
      },
    ]);
    const zoom = ref(2);

    const uploadImage = async (event) => {
      state.coverPreview = event.files[0].objectURL;

      const formData = new FormData();
      formData.append("image", event.files[0]);

      const response = await ArticleService.uploadImage(formData);

      if ((response.status = 200 && response.data.status == "success")) {
        state.cover = response.data.data.fileUrl;
        console.log(response.data);
        toast.add({
          severity: "success",
          summary: "Berhasil!",
          detail: response.data.message,
          life: 3000,
        });
      }
    };

    const createArticle = async (isFormValid) => {
      submitted.value = true;
      console.log(isFormValid);
      if (!isFormValid) {
        return;
      }
      const response = await ArticleService.createArticle({
        title: state.title,
        description: state.description,
        cover: state.cover,
        body: state.body,
      });

      if ((response.status = 200 && response.data.status == "success")) {
        toast.add({
          severity: "success",
          summary: "Berhasil!",
          detail: response.data.message,
          life: 3000,
        });
        await router.push({ name: "listArticle" });
      }
    };

    const uploaderContent = {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);
            ArticleService.uploadImage(formData)
              .then((res) => {
                console.log(res.data);
                resolve(res.data.data.fileUrl);
              })
              .catch((err) => {
                reject("Upload failed");
                console.error("Error:", err);
              });
          });
        },
      },
    };

    const htmlEdit = {
      name: "htmlEditButton",
      module: htmlEditButton,
    };

    return {
      FormatCurrency,
      isLoading,
      v$,
      submitted,
      goBack() {
        router.go(-1);
      },
      quillOptions,
      uploadImage,
      createArticle,
      uploaderContent,
      modules: [uploaderContent, htmlEdit],
      zoom,
      recyclers,
      deliveryList,
    };
  },
};
</script>

<style>
#map {
  height: 400px;
  border-radius: 12px;
}
</style>
