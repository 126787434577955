<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button icon="pi pi-arrow-left" class="p-button-info p-button-sm mr-2" @click="goBack()" />
        <div class="font-medium text-3xl text-900">Tambah Sub Kategori Sampah</div>
      </div>
    </div>
    <form @submit.prevent="createWasteSubCategory(!v$.$invalid)">
      <div class="grid p-fluid align-items-center">
        <div class="col-12">
          <div class="field">
            <label for="fixedPrice">Kategori Sampah</label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="v$.categoryId.$model"
                :options="categoryList"
                optionLabel="categoryName"
                optionValue="id"
                placeholder="Pilih Kategori Sampah"
                filterPlaceholder="Cari kategori sampah berdasarkan ID "
                :showClear="true"
                :filter="true"
                :class="{ 'p-invalid': v$.categoryId.$invalid && submitted }"
              />
            </div>
            <small
              v-if="(v$.categoryId.$invalid && submitted) || v$.categoryId.$pending.$response"
              class="p-error"
              >{{ v$.categoryId.required.$message.replace('Value', 'Inquiry Id') }}</small
            >
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="subCategoryCode">Kode Sub Kategori Sampah</label>
            <InputText type="text" v-model="v$.subCategoryCode.$model" />
            <small
              v-if="
                (v$.subCategoryCode.$invalid && submitted) || v$.subCategoryCode.$pending.$response
              "
              class="p-error"
            >
              {{ v$.goods.required.$message.replace('Value', 'Sub Category Code') }}
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="subCategoryName">Nama Sub Kategori Sampah</label>
            <InputText type="text" v-model="v$.subCategoryName.$model" />
            <small
              v-if="
                (v$.subCategoryName.$invalid && submitted) || v$.subCategoryName.$pending.$response
              "
              class="p-error"
            >
              {{ v$.subCategoryName.required.$message.replace('Value', 'Sub Category Name') }}
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="goods">Contoh Barang/Limbah</label>
            <InputText type="text" v-model="v$.goods.$model" />
            <small
              v-if="(v$.goods.$invalid && submitted) || v$.goods.$pending.$response"
              class="p-error"
            >
              {{ v$.goods.required.$message.replace('Value', 'Contoh Barang/Limbah') }}
            </small>
          </div>
        </div>
      </div>
      <div class="grid p-fluid justify-content-end">
        <div class="col-12 xl:col-2">
          <div class="field">
            <label for="button"></label>
            <Button
              type="submit"
              label="Buat Sub Kategori Sampah"
              class="p-button-success"
              :loading="isLoading"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive, ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import WasteService from '../../services/waste-info/WasteService';
import { onMounted } from '@vue/runtime-core';

export default {
  setup() {
    onMounted(() => {
      getListCategory();
    });

    const router = useRouter();
    const toast = useToast();

    const state = reactive({
      categoryId: null,
      subCategoryCode: null,
      subCategoryName: null,
      goods: null,
    });

    const categoryList = ref([]);

    const rules = {
      categoryId: { required },
      subCategoryCode: { required },
      subCategoryName: { required },
      goods: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);

    const getListCategory = async () => {
      try {
        isLoading.value = true;
        const response = await WasteService.getList();
        if ((response.status = 200 && response.data.status == 'success')) {
          categoryList.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const createWasteSubCategory = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      try {
        isLoading.value = true;
        const response = await WasteService.createSubCategory(
          JSON.stringify({
            categoryId: state.categoryId,
            subCategoryCode: state.subCategoryCode,
            subCategoryName: state.subCategoryName,
            goods: state.goods,
          }),
        );
        if ((response.status = 201 && response.data.status == 'success')) {
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
          });
          router.go(-1);
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      v$,
      submitted,
      categoryList,
      createWasteSubCategory,
      goBack() {
        router.go(-1);
      },
    };
  },
};
</script>

<style></style>
