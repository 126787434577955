<template>
  <Toast />
  <div v-if="isLoading" class="text-center">
    <ProgressBar mode="indeterminate" style="height: 0.5em" />
  </div>
  <div v-else class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button icon="pi pi-arrow-left" class="p-button-info p-button-sm mr-2" @click="goBack()" />
        <div class="font-medium text-3xl text-900">Konfirmasi Pembayaran Pembeli</div>
      </div>
    </div>
    <div>
      <div v-if="isInquiryLoading" class="text-center">
        <ProgressBar mode="indeterminate" style="height: 0.5em" />
      </div>
      <div v-if="inquiry" class="card">
        <div class="surface-section">
          <div class="flex align-items-center mb-3">
            <div class="font-medium text-3xl text-900">Informasi Detail Pesanan Information</div>
          </div>

          <Accordion>
          <AccordionTab
            v-for="(inquiryItem, index) in inquiry.inquiryItems"
            v-bind:key="index"
          >
            <template #header>
              <div class="flex flex-row w-full">
                <h5 class="my-auto">{{ inquiryItem.wasteSubCategoryName }}</h5>
              </div>
            </template>
            <ul class="list-none p-0 m-0">
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">
                  {{ inquiryItem.wasteSubCategoryName }}
                </div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ inquiryItem.wasteSubCategoryName }}
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Kuantitas</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ inquiryItem.quantity }}Kg
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Harga Minimum</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ FormatCurrency.toRupiah(inquiryItem.priceMinimum) }}
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Harga Maximum</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ FormatCurrency.toRupiah(inquiryItem.priceMaximum) }}
                </div>
              </li>
              <li
                class="
                  flex
                  align-items-center
                  py-3
                  px-2
                  border-top-1
                  surface-border
                  flex-wrap
                "
              >
                <div class="text-500 w-6 md:w-2 font-medium">Harga Tetap</div>
                <div
                  class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1"
                >
                  {{ FormatCurrency.toRupiah(inquiryItem.fixedPrice) }}
                </div>
              </li>
            </ul>
          </AccordionTab>
        </Accordion>

          <ul class="list-none p-0 m-0 mt-4">
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">ID Pesanan</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {{ inquiry.id }}
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Mitra Pituku</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {{ inquiry.wasteBank }}
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Nama Pembeli</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {{ inquiry.buyerName }}
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Kuantitas</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {{ inquiry.totalQuantity }}kg
              </div>
            </li>
            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div class="text-500 w-6 md:w-2 font-medium">Harga Tetap</div>
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                {{ FormatCurrency.toRupiah(invoice.total) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <form @submit.prevent="confirmPayment(!v$.$invalid)" class="card">
      <div class="grid p-fluid align-items-center">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="fixedPrice">Bank</label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="v$.bank.$model"
                :options="bankList"
                optionLabel="bank"
                optionValue="bank"
                placeholder="Pilih bank"
                filterPlaceholder="Cari bank berdasarkan nama"
                :showClear="true"
                :filter="true"
                :class="{ 'p-invalid': v$.bank.$invalid && submitted }"
              />
            </div>
            <small
              v-if="(v$.bank.$invalid && submitted) || v$.bank.$pending.$response"
              class="p-error"
              >{{ v$.bank.required.$message.replace('Value', 'Bank') }}</small
            >
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vehicle">No Rekening</label>
            <InputText type="text" v-model="v$.bankAccountNumber.$model" />
            <small
              v-if="
                (v$.bankAccountNumber.$invalid && submitted) ||
                v$.bankAccountNumber.$pending.$response
              "
              class="p-error"
            >
              {{ v$.bankAccountNumber.required.$message.replace('Value', 'Bank Account Number') }}
            </small>
          </div>
        </div>
      </div>
      <div class="grid p-fluid justify-content-end">
        <div class="col-12 xl:col-2">
          <div class="field">
            <label for="button"></label>
            <Button
              type="submit"
              label="Konfirmasi Pembayaran"
              class="p-button-success"
              :loading="isLoading"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive, ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import PaymentService from '../../services/payment/PaymentService';
import { onMounted } from '@vue/runtime-core';
import InquiryService from '../../services/inquiry/InquiryService';
import BankService from '../../services/bank/BankService';

import FormatDate from '../../services/helpers/FormatDate';
import FormatCurrency from '../../services/helpers/FormatCurrency';

export default {
  setup() {
    onMounted(() => {
      getDetailInquiry(route.params.id);
      getListBank();
    });

    const router = useRouter();
    const toast = useToast();
    const route = useRoute();

    const inquiryList = ref([]);
    const bankList = ref([]);
    const inquiry = ref(null);
    const invoice = ref(null);

    const state = reactive({
      bank: '',
      bankAccountNumber: '',
    });

    const rules = {
      bank: { required },
      bankAccountNumber: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);
    const isInquiryLoading = ref(false);

    const getListBank = async () => {
      try {
        isLoading.value = true;
        const response = await BankService.getList();
        if ((response.status = 200 && response.data.status == 'success')) {
          bankList.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const getDetailInquiry = async (id) => {
      try {
        isInquiryLoading.value = true;
        const response = await InquiryService.getDetail(id);
        if ((response.status = 201 && response.data.status == 'success')) {
          inquiry.value = response.data.data.inquiry;
          invoice.value = response.data.data.invoice;
          isInquiryLoading.value = false;
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isInquiryLoading.value = false;
      }
    };

    const confirmPayment = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      try {
        isLoading.value = true;
        const response = await PaymentService.confirmBuyer(
          JSON.stringify({
            inquiryId: route.params.id,
            bank: state.bank,
            bankAccountNumber: state.bankAccountNumber,
          }),
        );
        if ((response.status = 201 && response.data.status == 'success')) {
          await router.push({ name: 'detailInquiry' });
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      FormatDate,
      FormatCurrency,
      isLoading,
      isInquiryLoading,
      v$,
      submitted,
      confirmPayment,
      inquiryList,
      bankList,
      getDetailInquiry,
      inquiry,
      invoice,
      goBack() {
        router.go(-1);
      },
    };
  },
};
</script>

<style></style>
