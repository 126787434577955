import API from '../API';

class ArticleService {
  uploadImage(data) {
    return API.post('/api/articles/upload-image', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  listArticle() {
    return API.get('/api/articles');
  }
  detailArticle(id) {
    return API.get(`/api/articles/${id}`);
  }
  createArticle(data) {
    return API.post('/api/articles', data);
  }
  updateArticle(data) {
    return API.put(`/api/articles/${data.id}`, data);
  }
  deleteArticle(id) {
    return API.delete(`/api/articles/${id}`);
  }
}

export default new ArticleService();
