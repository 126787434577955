<template>
  <Toast />
  <div v-if="isLoading" class="text-center">
    <ProgressBar mode="indeterminate" style="height: 0.5em" />
  </div>
  <div v-else class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <div class="font-medium text-3xl text-900">Konfirmasi Pembayaran Mitra</div>
      </div>
    </div>
    <form @submit.prevent="confirmPayment(!v$.$invalid)">
      <div class="grid p-fluid align-items-center">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="fixedPrice">Bank</label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="v$.bank.$model"
                :options="bankList"
                optionLabel="bank"
                optionValue="bank"
                placeholder="Pilih bank"
                filterPlaceholder="Cari bank berdasarkan nama"
                :showClear="true"
                :filter="true"
                :class="{ 'p-invalid': v$.bank.$invalid && submitted }"
              />
            </div>
            <small
              v-if="(v$.bank.$invalid && submitted) || v$.bank.$pending.$response"
              class="p-error"
              >{{ v$.bank.required.$message.replace('Value', 'Bank') }}</small
            >
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vehicle">No Rekening</label>
            <InputText type="text" v-model="v$.bankAccountNumber.$model" />
            <small
              v-if="
                (v$.bankAccountNumber.$invalid && submitted) ||
                v$.bankAccountNumber.$pending.$response
              "
              class="p-error"
            >
              {{ v$.bankAccountNumber.required.$message.replace('Value', 'Bank Account Number') }}
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="receiveDateEstimation">Upload Bukti Pembayaran</label>
            <div class="mt-2">
              <a-upload-dragger
                id="file-upload"
                accept="image/png, image/jpg, image/jpeg"
                class="rounded"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @remove="handleRemove"
                list-type="picture"
                :max-count="3"
                :multiple="true"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  Pilih Gambar
                </a-button>
              </a-upload-dragger>
            </div>
          </div>
        </div>
      </div>
      <div class="grid p-fluid justify-content-end">
        <div class="col-12 xl:col-2">
          <div class="field">
            <label for="button"></label>
            <Button
              @click="handleUpload(!v$.$invalid)"
              label="Konfirmasi Pembayaran"
              class="p-button-success"
              :loading="isLoading"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive, ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import { UploadOutlined } from '@ant-design/icons-vue';

import PaymentService from '../../services/payment/PaymentService';
import { onMounted } from '@vue/runtime-core';
import BankService from '../../services/bank/BankService';

import FormatDate from '../../services/helpers/FormatDate';
import FormatCurrency from '../../services/helpers/FormatCurrency';
import authHeader from '../../services/authentication/AuthHeader';
import axios from 'axios';

export default {
  components: {
    UploadOutlined,
  },
  setup() {
    onMounted(() => {
      getListBank();
    });

    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const bankList = ref([]);

    const state = reactive({
      bank: '',
      bankAccountNumber: '',
    });

    const rules = {
      bank: { required },
      bankAccountNumber: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);

    /* Upload Handler */
    const fileList = ref([]);

    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const beforeUpload = (file) => {
      fileList.value = [...fileList.value, file];
      return false;
    };

    const handleUpload = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      const formData = new FormData();

      fileList.value.forEach((file) => {
        formData.append('transferProof', file);
      });
      try {
        isLoading.value = true;
        const response = await axios.post(
          process.env.VUE_APP_API_HOST + '/api/payments/seller/upload',
          formData,
          {
            headers: {
              Authorization: authHeader(),
            },
          },
        );

        if (response.data.status === 'success') {
          await confirmPayment(response.data.data.transferProof);
          isLoading.value = false;
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };
    /* Akhir Upload Handler */

    const getTagColor = (status) => {
      switch (status) {
        case 'Dipesan':
          return 'danger';
        case 'Belum Dikirim':
          return 'danger';
        case 'Menunggu Pembayaran':
          return 'warning';
        case 'Dalam Perjalanan':
          return 'warning';
        case 'Dibayar':
          return 'success';
        case 'Berhasil Dikirim':
          return 'success';
        default:
          return '';
      }
    };

    const getListBank = async () => {
      try {
        isLoading.value = true;
        const response = await BankService.getList();
        if ((response.status = 200 && response.data.status == 'success')) {
          bankList.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const confirmPayment = async (transferProof) => {
      try {
        isLoading.value = true;
        const response = await PaymentService.confirmSeller(
          JSON.stringify({
            withdrawId: route.params.id,
            bank: state.bank,
            bankAccountNumber: state.bankAccountNumber,
            transferProof: transferProof,
          }),
        );
        if ((response.status = 201 && response.data.status == 'success')) {
          setTimeout(
            () =>
              router.push({
                name: 'withdrawalSellerDetail',
              }),
            3000,
          );
          toast.add({
            severity: 'success',
            summary: 'Berhasil!',
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      FormatDate,
      FormatCurrency,
      isLoading,
      v$,
      submitted,
      confirmPayment,
      bankList,
      getTagColor,
      fileList,
      beforeUpload,
      handleRemove,
      handleUpload,
    };
  },
};
</script>

<style></style>
