<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-info p-button-sm mr-2"
          @click="goBack()"
        />
        <div class="font-medium text-3xl text-900">
          Tambah Informasi Invoice
        </div>
      </div>
    </div>
    <form @submit.prevent="createInvoice(!v$.$invalid)">
      <div class="grid p-fluid align-items-center">
        <div class="col-12">
          <Accordion>
            <AccordionTab
              v-for="(inquiryItem, index) in v$.wasteSubCategoryItems.$model"
              v-bind:key="index"
            >
              <template #header>
                <div class="flex flex-row w-full">
                  <h5 class="my-auto">
                    {{ inquiryItem.wasteSubCategoryName }}
                  </h5>
                </div>
              </template>
              <div class="field">
                <label for="fixedPrice">Harga Tetap </label>
                <div class="p-inputgroup">
                  <InputNumber
                    inputId="currency-id"
                    v-model="inquiryItem.fixedPrice"
                    mode="currency"
                    currency="IDR"
                    locale="id-ID"
                    :minFractionDigits="2"
                  />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="shippingCost">Biaya Pengiriman</label>
            <InputNumber
              inputId="currency-id"
              v-model="v$.shippingCost.$model"
              mode="currency"
              currency="IDR"
              locale="id-ID"
              :minFractionDigits="0"
            />
            <small
              v-if="
                (v$.shippingCost.$invalid && submitted) ||
                v$.shippingCost.$pending.$response
              "
              class="p-error"
              >{{
                v$.shippingCost.required.$message.replace(
                  "Value",
                  "Shipping Cost"
                )
              }}</small
            >
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="fixedPrice">Pajak</label>
            <InputNumber
              inputId="currency-id"
              v-model="v$.tax.$model"
              mode="currency"
              currency="IDR"
              locale="id-ID"
              :minFractionDigits="0"
            />
            <small
              v-if="(v$.tax.$invalid && submitted) || v$.tax.$pending.$response"
              class="p-error"
              >{{ v$.tax.required.$message.replace("Value", "Tax") }}</small
            >
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="fixedPrice">Diskon</label>
            <InputNumber
              inputId="currency-id"
              v-model="v$.discount.$model"
              mode="currency"
              currency="IDR"
              locale="id-ID"
              :minFractionDigits="0"
            />
            <small
              v-if="
                (v$.discount.$invalid && submitted) ||
                v$.discount.$pending.$response
              "
              class="p-error"
              >{{
                v$.discount.required.$message.replace("Value", "Discount")
              }}</small
            >
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="invoiceDate">Tanggal Invoice</label>
            <div class="p-inputgroup">
              <Calendar
                inputId="dateformat"
                v-model="v$.invoiceDate.$model"
                dateFormat="dd-mm-yy"
              />
            </div>
            <small
              v-if="
                (v$.invoiceDate.$invalid && submitted) ||
                v$.invoiceDate.$pending.$response
              "
              class="p-error"
            >
              {{
                v$.invoiceDate.required.$message.replace(
                  "Value",
                  "Tanggal Invoice"
                )
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="grid p-fluid justify-content-end">
        <div class="col-12 xl:col-2">
          <div class="field">
            <label for="button"></label>
            <Button
              type="submit"
              label="Buat Informasi Invoice"
              class="p-button-success"
              :loading="isLoading"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import InquiryService from "../../services/inquiry/InquiryService";
import FormatCurrency from "../../services/helpers/FormatCurrency";

import PaymentService from "../../services/payment/PaymentService";
import FormatDate from "../../services/helpers/FormatDate";
import { onMounted } from "@vue/runtime-core";

export default {
  setup() {
    onMounted(async () => {
      getDetailInquiry(route.params.id);
    });
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const state = reactive({
      wasteSubCategoryItems: [],
      shippingCost: null,
      tax: null,
      invoiceDate: null,
      discount: null,
    });

    const rules = {
      discount: { required },
      shippingCost: { required },
      tax: { required },
      invoiceDate: { required },
      wasteSubCategoryItems: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);

    // get inquiry data
    const getDetailInquiry = async (id) => {
      try {
        isLoading.value = true;
        const response = await InquiryService.getDetail(id);
        if ((response.status = 201 && response.data.status == "success")) {
          state.wasteSubCategoryItems = response.data.data.inquiry.inquiryItems;
        }
        isLoading.value = false;
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const createInvoice = async (isFormValid) => {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }

      try {
        isLoading.value = true;
        console.log({
          inquiryId: route.params.id,
          shippingCost: state.shippingCost,
          tax: state.tax,
          invoiceDate: FormatDate.toYYYYMMDD(state.invoiceDate),
          wasteSubCategoryItems: state.wasteSubCategoryItems,
          discount: state.discount,
        });
        const wasteSubCategoryItems = state.wasteSubCategoryItems.map(
          (item) => ({
            wasteSubCategoryId: item.wasteSubCategoryId,
            fixedPrice: item.fixedPrice,
          })
        );
        const response = await PaymentService.createInvoice(
          JSON.stringify({
            inquiryId: route.params.id,
            shippingCost: state.shippingCost,
            tax: state.tax,
            invoiceDate: FormatDate.toYYYYMMDD(state.invoiceDate),
            wasteSubCategoryItems: wasteSubCategoryItems,
            discount: state.discount,
          })
        );
        if ((response.status = 200 && response.data.status == "success")) {
          await router.push({ name: "detailInquiry" });
          toast.add({
            severity: "success",
            summary: "Berhasil!",
            detail: response.data.message,
            life: 3000,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: "error",
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: "error",
            summary: "ERR_CONNECTION_REFUSED",
            detail: error.request,
          });
        } else {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    return {
      FormatCurrency,
      isLoading,
      v$,
      submitted,
      createInvoice,
      goBack() {
        router.go(-1);
      },
    };
  },
};
</script>

<style></style>
