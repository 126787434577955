<template>
  <DataTable
    :value="buyerList"
    :paginator="true"
    class="p-datatable-buyers"
    :rows="6"
    dataKey="id"
    :rowHover="true"
    :loading="isLoading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Menampilkan {first} - {last} dari {totalRecords} baris"
    responsiveLayout="scroll"
    removableSort
    v-model:filters="filters"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <h5 class="m-0">Daftar Pembeli</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" placeholder="Pencarian…." />
        </span>
      </div>
    </template>
    <template #empty> No buyers found. </template>
    <Column header="Nama" style="min-width: 14rem">
      <template #body="{ data }"> {{ data.firstName }} {{ data.lastName }} </template>
    </Column>
    <Column field="email" header="Email" sortable style="min-width: 14rem"> </Column>
    <Column field="companyName" header="Nama Perusahaan" sortable style="min-width: 14rem"></Column>
    <Column field="phoneNumber" header="No. HP" sortable style="min-width: 14rem"></Column>
    <Column field="city" header="Kota Perusahaan" sortable style="min-width: 14rem"></Column>
    <Column field="province" header="Provinsi" sortable style="min-width: 14rem"></Column>
    <Toast />
  </DataTable>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';
import { useToast } from 'primevue/usetoast';
import BuyerService from '../../services/buyer/BuyerService';
import { FilterMatchMode } from 'primevue/api';

export default {
  setup() {
    onMounted(async () => {
      getAllBuyer();
    });

    const isLoading = ref(false);

    const toast = useToast();

    const buyerList = ref([]);

    const getAllBuyer = async () => {
      try {
        isLoading.value = true;
        const response = await BuyerService.getList();
        if ((response.status = 201 && response.data.status == 'success')) {
          buyerList.value = response.data.data;
          isLoading.value = false;
        }
      } catch (error) {
        if (error.response) {
          toast.add({
            severity: 'error',
            summary: error.response.data.status,
            detail: error.response.data.message,
          });
        } else if (error.request) {
          toast.add({
            severity: 'error',
            summary: 'ERR_CONNECTION_REFUSED',
            detail: error.request,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        }
        isLoading.value = false;
      }
    };

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    return {
      isLoading,
      toast,
      buyerList,
      filters,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datatable.p-datatable-buyers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}
</style>
