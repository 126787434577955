<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-info p-button-sm mr-2"
          @click="goBack()"
        />
        <div class="font-medium text-3xl text-900">Update Artikel</div>
        <Button
          icon="pi pi-save"
          label="Simpan"
          class="p-button-success p-button-sm ml-auto"
          @click="updateArticle(true)"
        />
      </div>
    </div>
    <form>
      <div class="grid p-fluid">
        <div class="col-8 mb-8">
          <QuillEditor
            theme="snow"
            toolbar="full"
            :options="quillOptions"
            style="min-height: 200px"
            contentType="html"
            v-model:content="v$.body.$model"
            :modules="modules"
          />
          <small
            v-if="(v$.body.$invalid && submitted) || v$.body.$pending.$response"
            class="p-error"
            >{{
              v$.body.required.$message.replace("Value", "Konten Artikel")
            }}</small
          >
        </div>
        <div class="col-4">
          <div class="field">
            <label for="title">Slug Artikel</label>
            <div class="p-inputgroup">
              <InputText inputId="slug" v-model="v$.slug.$model" />
            </div>
            <small
                v-if="
                (v$.slug.$invalid && submitted) || v$.slug.$pending.$response
              "
                class="p-error"
            >{{
                v$.slug.required.$message.replace("Value", "SlugArtikel")
              }}</small
            >
          </div>
          <div class="field">
            <label for="title">Judul Artikel</label>
            <div class="p-inputgroup">
              <InputText inputId="title" v-model="v$.title.$model" />
            </div>
            <small
              v-if="
                (v$.title.$invalid && submitted) || v$.title.$pending.$response
              "
              class="p-error"
              >{{
                v$.title.required.$message.replace("Value", "Judul Artikel")
              }}</small
            >
          </div>
          <div class="field">
            <label for="description">Deskripsi Artikel</label>
            <div class="p-inputgroup">
              <Textarea
                inputId="description"
                v-model="v$.description.$model"
                rows="5"
                cols="30"
                :autoResize="true"
              />
            </div>
            <small
              v-if="
                (v$.description.$invalid && submitted) ||
                v$.description.$pending.$response
              "
              class="p-error"
              >{{
                v$.description.required.$message.replace(
                  "Value",
                  "Deskripsi Artikel"
                )
              }}</small
            >
          </div>
          <div class="field">
            <label for="upload">Cover Artikel</label>
            <FileUpload
              mode="basic"
              @uploader="uploadImage"
              :customUpload="true"
              :auto="true"
            />
            <small
              v-if="
                (v$.coverPreview.$invalid && submitted) ||
                v$.coverPreview.$pending.$response
              "
              class="p-error"
              >{{
                v$.coverPreview.required.$message.replace(
                  "Value",
                  "Cover Artikel"
                )
              }}</small
            >
            <img
              v-if="v$.coverPreview.$model"
              :src="v$.coverPreview.$model"
              alt="Cover Preview"
              class="mt-4 w-full"
              style="border-radius: 12px"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

  <script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, ref } from "@vue/reactivity";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import FormatCurrency from "../../services/helpers/FormatCurrency";
import { onMounted } from "@vue/runtime-core";
import ArticleService from "../../services/article/ArticleService";
import ImageUploader from "quill-image-uploader";
import htmlEditButton from "quill-html-edit-button";

export default {
  setup() {
    onMounted(async () => {
      getArticle(route.params.id);
    });
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const quillOptions = {
      // debug: "info",
      modules: {},
      placeholder: "Compose an epic...",
      theme: "snow",
    };

    const articleId = ref(0);

    const state = reactive({
      wasteSubCategoryItems: [],
      shippingCost: null,
      tax: null,
      invoiceDate: null,
      discount: null,
      title: null,
      slug: null,
      description: null,
      coverPreview: null,
      cover: null,
      body: "",
    });

    const rules = {
      title: { required },
      slug: { required },
      description: { required },
      cover: { required },
      coverPreview: { required },
      body: { required },
    };

    const v$ = useVuelidate(rules, state);
    const submitted = ref(false);
    const isLoading = ref(false);

    const uploadImage = async (event) => {
      state.coverPreview = event.files[0].objectURL;

      const formData = new FormData();
      formData.append("image", event.files[0]);

      const response = await ArticleService.uploadImage(formData);

      if ((response.status = 200 && response.data.status == "success")) {
        state.cover = response.data.data.fileUrl;
        console.log(response.data);
        toast.add({
          severity: "success",
          summary: "Berhasil!",
          detail: response.data.message,
          life: 3000,
        });
      }
    };

    const getArticle = async (id) => {
      articleId.value = id;
      ArticleService.detailArticle(id)
        .then((res) => {
          if ((res.status = 201 && res.data.status == "success")) {
            const { title, description, slug, cover, body } = res.data.data;
            state.title = title;
            state.slug = slug;
            state.description = description;
            state.cover = cover;
            state.body = body;
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
            });
          } else if (err.request) {
            toast.add({
              severity: "error",
              summary: "ERR_CONNECTION_REFUSED",
              detail: err.request,
            });
          } else {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
            });
          }
        });
    };

    const updateArticle = async (isFormValid) => {
      submitted.value = true;
      console.log(isFormValid);
      if (!isFormValid) {
        return;
      }
      const response = await ArticleService.updateArticle({
        id: articleId.value,
        title: state.title,
        slug: state.slug,
        description: state.description,
        cover: state.cover,
        body: state.body,
      });

      if ((response.status = 200 && response.data.status == "success")) {
        toast.add({
          severity: "success",
          summary: "Berhasil!",
          detail: response.data.message,
          life: 3000,
        });
        await router.push({ name: "listArticle" });
      }
    };

    const uploaderContent = {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);
            ArticleService.uploadImage(formData)
              .then((res) => {
                console.log(res.data);
                resolve(res.data.data.fileUrl);
              })
              .catch((err) => {
                reject("Upload failed");
                console.error("Error:", err);
              });
          });
        },
      },
    };

    const htmlEdit = {
      name: "htmlEditButton",
      module: htmlEditButton,
    };

    return {
      FormatCurrency,
      isLoading,
      v$,
      submitted,
      goBack() {
        router.go(-1);
      },
      quillOptions,
      uploadImage,
      updateArticle,
      uploaderContent,
      modules: [uploaderContent, htmlEdit],
    };
  },
};
</script>

  <style></style>
