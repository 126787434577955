import API from "../API";

class DeliveryService {
  queryAutoCompletePlaces(query) {
    return API.get(`/delivery/places?input=${query}`);
  }
  getPlace(id) {
    return API.get(`/delivery/places/${id}`);
  }
  getPolyline(latlong = []) {
    const locations = [];
    latlong.forEach((item) => {
      locations.push(item.join(","));
    });
    const parsedLocation = locations.join(";");
    return API.get(`/delivery/places/directions/${parsedLocation}`);
  }
  getNearestRecyclers(payload) {
    return API.post("/delivery/nearest-recyclers", payload);
  }
  getWasteTypes() {
    return API.get("/delivery/waste-types");
  }
  addWasteTypes(payload) {
    return API.post("/delivery/waste-types", payload);
  }
  getWasteSubTypes(wasteTypeId) {
    return API.get(`/delivery/waste-types/${wasteTypeId}/waste-sub-types`);
  }
  addWasteSubTypes(payload) {
    return API.post(`/delivery/waste-types/${payload.wasteTypeId}/waste-sub-types`, {
      wasteSubTypeName: payload.wasteSubTypeName,
    });
  }
  getRecyclers() {
    return API.get("/delivery/recyclers");
  }
  addRecyclers(payload) {
    return API.post("/delivery/recyclers", payload);
  }
  getDrivers() {
    return API.get("/delivery/drivers");
  }
  addDrivers(payload) {
    return API.post("/delivery/drivers", payload);
  }
  getVehicles() {
    return API.get("/delivery/vehicles");
  }
  addVehicles(payload) {
    return API.post("/delivery/vehicles", payload);
  }
  getProducers() {
    return API.get("/delivery/producers");
  }
  addProducers(payload) {
    return API.post("/delivery/producers", payload);
  }
  getDelivery() {
    return API.get("/delivery/orders")
  }
  addDelivery(payload) {
    return API.post("/delivery/orders", payload);
  }
}

export default new DeliveryService();
