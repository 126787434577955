<template>
  <div class="card">
    <div class="surface-section">
      <div class="flex align-items-center mb-3">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-info p-button-sm mr-2"
          @click="goBack()"
        />
        <div class="font-medium text-3xl text-900">Tambah Driver</div>
        <Button
          icon="pi pi-save"
          label="Simpan"
          class="p-button-success p-button-sm ml-auto"
          @click="addDriver()"
        />
      </div>
    </div>
    <form class="mt-5">
      <div class="grid p-fluid">
        <div class="col-2 mb-2">
          <div class="p-5">
            <img
              src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=2000"
              style="width: 100%; border-radius: 100%"
              alt="Image"
            />
          </div>
        </div>
        <div class="col-5">
          <div class="field">
            <label for="title">Nama Lengkap</label>
            <div class="p-inputgroup">
              <InputText type="text" v-model="v$.fullname.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">No HP</label>
            <div class="p-inputgroup">
              <InputText type="number" v-model="v$.phoneNumber.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">Plat No</label>
            <div class="p-inputgroup">
              <InputText type="text" v-model="v$.numberPlate.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">Nama Perusahaan</label>
            <div class="p-inputgroup">
              <InputText type="text" v-model="v$.companyName.$model" />
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="field">
            <label for="title">Email</label>
            <div class="p-inputgroup">
              <InputText type="email" v-model="v$.email.$model" />
            </div>
          </div>
          <div class="field">
            <label for="title">Address</label>
            <div class="p-inputgroup">
              <AutoComplete
                v-model="v$.address.$model"
                optionLabel="value"
                :suggestions="suggestionLocations"
                @complete="onCompleteLocation"
                @item-select="onCompletedLocationClick"
                :delay="delay"
              />
            </div>
          </div>
          <div class="field">
            <label for="title">Jenis Kendaraan</label>
            <div class="p-inputgroup">
              <Dropdown
                v-model="v$.vehicleId.$model"
                :options="vehicles"
                optionLabel="vehicleName"
                optionValue="vehicleId"
                placeholder="Pilih jenis kendaraan"
              />
              <Button
                icon="pi pi-plus"
                label="New"
                class="p-button-success p-button-sm p-button-outlined ml-auto"
                @click="showModalCreateVehicle = true"
              />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div>
            <div id="map" />
          </div>
        </div>
      </div>
    </form>
  </div>

  <Dialog
    v-model:visible="showModalCreateVehicle"
    modal
    header="Tambah Jenis Kendaraan"
    :style="{ width: '50vw' }"
  >
    <div class="grid p-fluid">
      <div class="col-6">
        <div class="field">
          <label for="title">Nama Jenis Kendaraan</label>
          <div class="p-inputgroup">
            <InputText v-model="createVehicle$.vehicleName.$model" />
          </div>
        </div>
        <div class="field">
          <label for="title">Panjang</label>
          <div class="p-inputgroup">
            <InputText
              type="number"
              v-model="createVehicle$.vehicleLength.$model"
            />
          </div>
        </div>
        <div class="field">
          <label for="title">Tinggi</label>
          <div class="p-inputgroup">
            <InputText type="number" v-model="createVehicle$.height.$model" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="field">
          <label for="title">Muatan KG</label>
          <div class="p-inputgroup">
            <InputText type="number" v-model="createVehicle$.load.$model" />
          </div>
        </div>
        <div class="field">
          <label for="title">Lebar</label>
          <div class="p-inputgroup">
            <InputText type="number" v-model="createVehicle$.width.$model" />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        class="p-button-outlined"
        label="No"
        icon="pi pi-times"
        @click="showModalCreateVehicle = false"
        text
      />
      <Button
        label="Tambah"
        icon="pi pi-check"
        @click="addVehicles()"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import FormatCurrency from "../../services/helpers/FormatCurrency";
import { onMounted } from "@vue/runtime-core";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DeliveryService from "../../services/delivery/DeliveryService";

export default {
  setup() {
    let map = null;
    onMounted(async () => {
      getVehicles();
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZmFjaHJ5cGl0dWt1IiwiYSI6ImNsaXM4YWltdDI5NXIzZWxnMzk1Z2Z1N24ifQ.XFdZGywcziEcmfRgJxD_jQ";
      map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v9",
        zoom: 12,
        center: [106.856694, -6.425616],
      });
      map.on("load", () => {
        // TODO: Here we want to load a layer
        // TODO: Here we want to load/setup the popup
      });
    });
    const router = useRouter();
    const toast = useToast();
    const delay = 2000;

    const quillOptions = {
      // debug: "info",
      modules: {},
      placeholder: "Compose an epic...",
      theme: "snow",
    };

    const state = reactive({
      fullname: null,
      companyName: null,
      address: null,
      latitude: null,
      longitude: null,
      vehicleId: null,
      numberPlate: null,
      email: null,
      phoneNumber: null,
    });
    const rules = {
      fullname: { required },
      companyName: { required },
      address: { required },
      latitude: { required },
      longitude: { required },
      vehicleId: { required },
      numberPlate: { required },
      email: { required },
      phoneNumber: { required },
    };
    const v$ = useVuelidate(rules, state);

    const createVehicleState = reactive({
      vehicleName: null,
      load: null,
      vehicleLength: null,
      width: null,
      height: null,
    });
    const createVehicleRules = {
      vehicleName: { required },
      load: { required },
      vehicleLength: { required },
      width: { required },
      height: { required },
    };
    const createVehicle$ = useVuelidate(createVehicleRules, createVehicleState);

    const submitted = ref(false);
    const isLoading = ref(false);
    const zoom = ref(2);
    const vehicles = ref([]);
    const suggestionLocations = ref([]);
    const showModalCreateVehicle = ref(false);

    const getVehicles = async () => {
      isLoading.value = true;
      DeliveryService.getVehicles()
        .then((res) => {
          vehicles.value = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const addVehicles = async () => {
      isLoading.value = true;
      DeliveryService.addVehicles({
        vehicleName: createVehicleState.vehicleName,
        load: parseInt(createVehicleState.load),
        length: parseInt(createVehicleState.vehicleLength),
        width: parseInt(createVehicleState.width),
        height: parseInt(createVehicleState.height),
      })
        .then(async (res) => {
          if ((res.status = 200 && res.data.status == "success")) {
            toast.add({
              severity: "success",
              summary: "Berhasil!",
              detail: res.data.message,
              life: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
            });
          } else if (err.request) {
            toast.add({
              severity: "error",
              summary: "ERR_CONNECTION_REFUSED",
              detail: err.request,
            });
          } else {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
            });
          }
        })
        .finally(() => {
          getVehicles();
          isLoading.value = false;
          showModalCreateVehicle.value = false;
          createVehicleState.vehicleName = null;
          createVehicleState.load = null;
          createVehicleState.vehicleLength = null;
          createVehicleState.width = null;
          createVehicleState.height = null;
        });
    };

    const addDriver = async () => {
      isLoading.value = true;
      DeliveryService.addDrivers({
        fullname: state.fullname,
        companyName: state.companyName,
        address: state.address.value,
        latitude: state.latitude,
        longitude: state.longitude,
        vehicleId: state.vehicleId,
        numberPlate: state.numberPlate,
        email: state.email,
        phoneNumber: state.phoneNumber,
      })
        .then(async (res) => {
          if ((res.status = 200 && res.data.status == "success")) {
            toast.add({
              severity: "success",
              summary: "Berhasil!",
              detail: res.data.message,
              life: 3000,
            });
          }
          await router.push({ name: "listDriver" });
        })
        .catch((err) => {
          if (err.response) {
            toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
            });
          } else if (err.request) {
            toast.add({
              severity: "error",
              summary: "ERR_CONNECTION_REFUSED",
              detail: err.request,
            });
          } else {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err,
            });
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const onCompleteLocation = async (event) => {
      const res = await DeliveryService.queryAutoCompletePlaces(event.query);
      suggestionLocations.value = res.data.predictions.map((location) => ({
        value: location.description,
        id: location.place_id,
      }));
    };

    const onCompletedLocationClick = async (event) => {
      const res = await DeliveryService.getPlace(event.value.id);
      state.latitude = res.data.result.geometry.location.lat;
      state.longitude = res.data.result.geometry.location.lng;
      state.marker = new mapboxgl.Marker()
        .setLngLat([
          res.data.result.geometry.location.lng,
          res.data.result.geometry.location.lat,
        ])
        .addTo(map);

      map.flyTo({
        center: [
          res.data.result.geometry.location.lng,
          res.data.result.geometry.location.lat,
        ],
      });
    };

    return {
      FormatCurrency,
      isLoading,
      v$,
      submitted,
      goBack() {
        router.go(-1);
      },
      quillOptions,
      zoom,
      addDriver,
      vehicles,
      onCompletedLocationClick,
      suggestionLocations,
      onCompleteLocation,
      delay,
      showModalCreateVehicle,
      createVehicle$,
      addVehicles,
    };
  },
};
</script>

<style>
#map {
  height: 400px;
  border-radius: 12px;
}
</style>
